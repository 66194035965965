import React from 'react'
import className from 'classnames/bind'
import { Typography } from 'antd';
import { numberFormat } from '@wadiz/utils';
import style from './CreateAdStep3.module.scss'
import { AreaType } from '@common'
import { AdStaticPreview } from '@common/ui'
import PreviewContainer from '../../../containers/PreviewContainer/PreviewContainer'

const cx = className.bind(style)
const { Text } = Typography;

const CreateAdStep3 = ({
  isComplete,
  onClickStep,
  onClickToAdList,
  onClickOpenReservationPaymentDialog,
  stepThirdAccountListDel,
  handleToAdStep,
  ...rest
}) => {
  const comma = (val) => {
    if (typeof val === 'string') return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (typeof val === 'number') return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return val
  }
  const displayEndDateFormat = (endAt) => {
    const newEndAt = new Date(endAt.replace(/\s/, 'T'))
    const displayEndDate = new Date(newEndAt.getFullYear(), newEndAt.getMonth(), newEndAt.getDate())

    return `${String(displayEndDate.getFullYear()).slice(2)}-${(displayEndDate.getMonth() + 1) / 10 >= 1 ? displayEndDate.getMonth() + 1 : `0${displayEndDate.getMonth() + 1}`
      }-${displayEndDate.getDate() / 10 >= 1 ? displayEndDate.getDate() : `0${displayEndDate.getDate()}`}`
  }
  return (
    <>
      {/* 컨텐츠 */}
      <div className={cx('create_ad', 'create_ad_container_step3')}>
        <hgroup>
          <h2>광고만들기</h2>
          <h3>광고비 결제 예약 하기</h3>
          <p>
            본 단계에서는 바로 결제 되지 않으며, 광고 심사 후 승인이 완료되면, 지금 예약하신 광고비의 결제가 진행됩니다.
          </p>
        </hgroup>
        {!isComplete ? (
          <section className={cx('ad_payment_list')}>
            {rest.accountList &&
              (rest.accountList.length > 0 ? (
                rest.accountList.map((val, idx) => (
                  <div key={idx}>
                    <dl>
                      <dt>
                        {val.positionName}
                        <button
                          type="button"
                          className={cx('wz button circular mini')}
                          onClick={(e) => stepThirdAccountListDel(e, idx)}
                        >
                          삭제
                        </button>
                      </dt>
                      <dd>
                        <span className={cx('payment_list_date')}>
                          {val.startAt.slice(2, 10)} ~ {displayEndDateFormat(val.endAt)}
                        </span>
                        {
                          val.price > 0 && (
                            <Text style={{ textAlign: 'right', display: 'block', width: '100%', color: '#90949c' }}>
                              평일: {val.normalPeriod}일 - {numberFormat(val.price * val.normalPeriod) || 0}원 /
                              휴일: {val.holidayPeriod}일 - {numberFormat(val.holidayPrice * val.holidayPeriod) || 0}원
                            </Text>
                          )
                        }
                      </dd>
                      {/* 미리보기 - 고정 이미지 인 경우 */}
                      <AdStaticPreview areaId={val.info.adCenterPubAreaId} />
                      {/* 앱푸시 || 이미지가 있는 경우 */}
                      {(val.info.adCenterPubAreaId === AreaType.messagePush || val.imgUrl) && (
                        <PreviewContainer
                          mainCopy={val.mainCopy}
                          keyword={val.keyword}
                          subCopy={val.subCopy}
                          imgUrl={val.imgUrl}
                          bgColor={val.selColor}
                          area={val.positionName}
                          accountNum={val.accountNum}
                          adCenterPubAreaId={val.adCenterPubAreaId}
                        />
                      )}
                      {/* <PreviewContainer
                        mainCopy={val.mainCopy}
                        keyword={val.keyword}
                        subCopy={val.subCopy}
                        imgUrl={val.imgUrl}
                        bgColor={val.selColor}
                        area={val.positionName}
                        accountNum={val.accountNum}
                        adCenterPubAreaId={val.adCenterPubAreaId}
                      /> */}
                      {/* <dd className={cx('payment_list_price')}><strong>{comma(val.discountPrice || val.price)}원</strong></dd> */}
                    </dl>
                  </div>
                ))
              ) : (
                <div>
                  <dl className={cx('thad_listNull')}>
                    <i className={cx('icon error-o')}></i>
                    <p>등록된 광고 구좌가 없습니다.</p>
                  </dl>
                </div>
              ))}
            {!isComplete && (
              <div className={cx('ad_payment')}>
                <div className={cx('payment_count')}>
                  <h3>광고 예상 금액</h3>
                  <p>
                    광고 금액<em>{comma(rest.adsPrice)}원</em>
                    <br></br>
                    부가세<em>{comma(rest.adsVat)}원</em>
                  </p>
                  <strong>
                    {comma(rest.totalPrice)}
                    <small>원</small>
                  </strong>
                </div>
                <button
                  type="button"
                  className={cx('wz button primary-outline')}
                  onClick={() => onClickOpenReservationPaymentDialog()}
                >
                  심사 요청 및 결제 예약하기
                </button>
                <p>
                  <i className={cx('icon error-o')}></i>위 버튼 클릭을 해야만 등록한 광고의 접수가 완료됩니다.{' '}
                  <em className={cx('point_primary')}>광고 심사 시 약 3 영업일이 소요</em>되며, MY광고관리에서
                  진행단계가 표시됩니다.
                  <span className={cx('point_red')}>
                    아직 최종 결제 단계가 아닙니다. <br />
                    결제 예약 후, 광고담당자의 승인 시 등록한 카드로 자동결제 됩니다.
                  </span>
                </p>
              </div>
            )}
            <div className={cx('buttonArea')}>
              <button
                type="button"
                className={cx('wz button less', 'button_historyBack')}
                onClick={() => handleToAdStep(2)}
              >
                <i className={cx('icon chevron-left')} />
                이전
              </button>
            </div>
          </section>
        ) : (
          <section className={cx('ad_paid')}>
            <p>
              <strong>
                총 금액 <em className={cx('point_primary')}>{comma(rest.totalPrice)}원</em>이 결제 예약 완료되어 광고
                승인요청 되었습니다.
              </strong>
              1. 요청한 광고의 승인 여부는 3영업일 이내에 메이커님의 프로젝트 등록 시 기재하신 이메일을 통해
              안내드립니다.
              <br />
              2. 설정한 날짜에 먼저 광고 요청한 메이커가 있을 경우, 일정 변경을 요청드릴 수 있습니다.
              <br />
              3. 2개 이상의 광고를 한 번에 결제 예약할 경우, 심사 승인 여부에 따라 결제가 일부만 진행될 수 있습니다.
              <br />
              4. 심사가 승인되면 광고비가 결제됩니다.
              <br />
            </p>
            <small>
              결제 예약한 광고가 심사 중일 때는 <em className={cx('point_primary')}>[요청]</em>으로 표시됩니다.<br></br>
              승인이 완료되면 <em className={cx('point_primary')}>[예정]</em>으로 표시됩니다.<br></br>
              요청한 광고에 수정이 필요할 경우 <em className={cx('point_primary')}>[피드백]</em>, 진행 불가할 경우{' '}
              <em className={cx('point_primary')}>[반려]</em>됩니다.<br></br>
              해당 내용은 MY광고관리 및 자동메일로 확인하실 수 있습니다.
            </small>
            <p className={cx('point_red')}>결제 예약 후, 심사 승인이 완료된 광고 건에 한하여 카드결제가 진행됩니다.</p>
            <button type="button" className={cx('wz button primary', 'button_320')} onClick={onClickToAdList}>
              MY 광고관리
            </button>
          </section>
        )}
      </div>
    </>
  )
}

export default CreateAdStep3
