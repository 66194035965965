// eslint-disable-next-line
import React from 'react'
import { MessageBox } from '@wadiz/waffle'
import style from '../../Dialog.module.scss'

type TypeContents = {
  title: string
  content: string
}
type ReservationState = {
  closed: TypeContents
  // imminent: TypeContents;
}
const reservationState: ReservationState = {
  closed: {
    title: '설정하신 기간의 예약이 마감되었습니다.',
    content: `이전 페이지로 돌아가서 기간을 변경해주세요.`,
  },
  // XXX 마감 임박은 공지하지 않는 것으로 기획 수정됨 .
  // imminent: {
  //   title: '이전 페이지로 돌아가서 기간을 변경해주세요.',
  //   content: `설정하신 기간에 먼저 요청한 다른 메이커님의 광고 심사 건으로 인해,
  //   해당 일정은 마감될 확률이 높습니다. 예약은 가능하나 해당 광고가
  //   마감될 경우 기간 변경을 요청드릴 수 있습니다.
  //   그래도 결제 예약을 진행하시려면 그대로 결제 예약하기 버튼을 눌러주세요.`,
  // },
}

type PayConfirmProps = {
  onClose: () => void
  onPay: () => void
  closeText: string
  loading: boolean
  disabled: boolean
  confirmText: string
  // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
  resState: 'Closed' | 'Imminent' | 'Available' | 'Unavailable'
}
export const PayConfirm: React.FC<PayConfirmProps> = ({ onClose, onPay, closeText, loading, disabled, resState }) => (
  <footer className={style.dialog_button} style={{ marginTop: '24px' }}>
    {resState === 'Closed' && (
      <MessageBox
        color={'danger'}
        title={reservationState.closed.title}
        description={reservationState.closed.content}
        isVertical={true}
        style={{ marginBottom: '16px' }}
      />
    )}
    <button type="button" className={'wz button'} onClick={onClose}>
      {closeText}
    </button>
    {resState !== 'Closed' && (
      <button
        type="button"
        className={`wz button primary ${loading ? 'loading' : ''}`}
        onClick={onPay}
        disabled={disabled}
      >
        결제 예약
      </button>
    )}
  </footer>
)
