import { useEffect, useState } from 'react'
import { Modal, Button, Radio, Divider, Typography } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import cx from 'classnames'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { HeadNotify, MiddleNotify, AdGuide, SelectGoods, SelectDate } from './components'
import { LoadingBar } from '@common/ui'
import { useBusinessDays } from '@center/query'
import type { Goods, PubAreaItem } from '@center/api'
import { numberFormat } from '@wadiz/utils'
import { AreaType, type AreaIDType } from '@common'

import { usePubAreaList, useAdAccountDetailList, useAdGoodsList } from './hooks/queries'
import styles from './AdRegistrationModal.module.scss'
import dayjs from 'dayjs'

interface AdRegistrationModalProps {
  isVisible: boolean
  onClose: () => void
  comingsoon: number
  projectType: number
  campaignId: number
  isWadizAdmin: boolean
  isSuperUser: boolean
  onFinish: (params: any) => void
}

interface DateRange {
  startDate: string // dateFormat
  endDate: string // dateFormat
}

const { Group } = Radio
const { Title } = Typography

const settings = {
  dots: true,
  infinite: true,
  speed: 400,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
}
const hiddenUserList: AreaIDType[] = [
  AreaType.searchKeyword,
  AreaType.mainHome,
  AreaType.mainBanner,
  AreaType.mainContentC,
  AreaType.searchBoard,
  AreaType.mainContentB,
]

const dateFormat = 'YYYY-MM-DD'

export const AdRegistrationModal: React.FC<AdRegistrationModalProps> = ({
  isVisible,
  onClose,
  isWadizAdmin = false,
  isSuperUser = false,
  comingsoon,
  projectType,
  campaignId,
  onFinish,
}) => {
  const { data: accountList } = usePubAreaList({ isWadizAdmin, projectType, comingsoon, isSuperUser })
  const [selectedAccount, setSelectAccount] = useState<PubAreaItem>({} as PubAreaItem)
  const accountDetailList = useAdAccountDetailList(selectedAccount?.areas || [], isWadizAdmin)
  const { data: businessDays } = useBusinessDays({ startDate: dayjs().format(dateFormat) })

  const { data: goodsList } = useAdGoodsList({
    id: accountDetailList[0]?.id || 0,
    isWadizAdmin,
    isPackaged: Boolean(selectedAccount.isPackage),
    projectType,
    campaignId,
  })
  const [selectedDate, setDate] = useState<DateRange>({
    startDate: dayjs().add(1, 'day').format(dateFormat),
    endDate: '',
  })
  const [selectedGoods, setSelectGoods] = useState<Goods>({} as Goods)
  const [checkHolidays, setCheckHolidays] = useState({
    normalDays: 0,
    holidays: 0,
  })

  const handleDate = (date: DateRange) => {
    setDate(date)
  }

  const handleAccount = (account: PubAreaItem) => {
    setSelectAccount(account)
    setSelectGoods({} as Goods)
  }

  const handleGoods = (goods: Goods) => {
    setSelectGoods(goods)
    setDate({
      startDate: dayjs().add(1, 'day').format(dateFormat),
      endDate: dayjs().add(goods.impMinPeriod, 'day').format(dateFormat),
    })
  }

  const nextStep = () => {
    // 구좌 정보
    const accountInfo = {
      ...accountDetailList![0],
      adCenterPubAreaId: selectedAccount.areas[0].id,
      isPackaged: selectedAccount.isPackage,
    }

    // packagedId 값이 있다면 패키지인 상품
    const packagedInfo = selectedAccount.isPackage
      ? {
          accountItem: {
            ...accountDetailList[1], // 서브 패키지 계정 정보
            adCenterPubAreaId: selectedAccount.areas[0].id,
          },
          goodsItem: goodsList!.result,
          positionName: selectedAccount.name,
          manualUrl: selectedAccount.areas[0].manualUrl,
        }
      : undefined

    // 비효율적이지만 이 값을 받는 상위에서 이렇게 받고 있음.
    const adData = {
      areaInfo: {
        ...selectedAccount,
        isPackaged: selectedAccount.isPackage,
      },
      accountInfo,
      goodsList: goodsList!.result,
      packagedInfo,
      goodsIdx: goodsList!.result.findIndex((item) => item.id === selectedGoods.id),
      selectedGoods: selectedGoods,
      adStartDate: dayjs(selectedDate.startDate),
      adEndDate: dayjs(selectedDate.endDate),
      accountList: [
        {
          ...accountInfo,
          positionName: selectedAccount.name,
          startAt: dayjs(selectedDate.startDate).format('YYYY-MM-DD'),
          endAt: dayjs(selectedDate.endDate).format('YYYY-MM-DD'),
          goodsIdx: goodsList!.result.findIndex((item) => item.id === selectedGoods.id),
          goodsid: selectedGoods.id,
          goodsList: goodsList!.result,
          manualUrl: selectedAccount.areas[0].manualUrl,
          selectedGoods,
          impPeriod: selectedGoods.impPeriod,
          impNum: selectedGoods.impNum,
          price: selectedGoods.price,
          holidayPrice: selectedGoods.holidayPrice,
          discount: selectedGoods.discount,
          goodsNum: selectedGoods.goodsNum,
          packagedInfo,
          isAdded: true,
          holidayPeriod: checkHolidays.holidays,
          normalPeriod: checkHolidays.normalDays,
        },
      ],
    }
    onFinish(adData)
  }

  const hiddenListForUser = (id: AreaIDType) => {
    if (hiddenUserList.includes(id) && !isSuperUser) return true
    return false
  }

  useEffect(() => {
    // 초기 구좌 설정
    if (accountList) {
      const checkDefaultAccount = () => {
        if (!accountList) return 0
        const availableIndex = accountList.findIndex((i) => i.areas[0].available === 0)
        return availableIndex
      }

      setSelectAccount(accountList[checkDefaultAccount()])
    }
  }, [accountList])

  useEffect(() => {
    if (!businessDays || !selectedGoods.id) return
    // 일반 = o, 공휴일 = h, 대체공휴일 = i, 임시공휴일 = t
    const holidayList = businessDays.result.filter((item) => item.type !== 'o')
    let normalDays = 0,
      holidays = 0
    // 휴일, 평일 체크
    const dateRange = new Array(selectedGoods.impMinPeriod)
      .fill('')
      .map((_, idx) => dayjs(selectedDate.startDate).add(idx, 'day').format(dateFormat))
    dateRange.forEach((date) => {
      if (holidayList.some((i) => date === i.eventDate)) {
        holidays += 1
      } else {
        normalDays += 1
      }
    })

    setCheckHolidays({
      normalDays,
      holidays,
    })
  }, [selectedDate, businessDays, selectedGoods])

  return (
    <Modal
      open={isVisible}
      width={920}
      onCancel={onClose}
      title={
        <div style={{ padding: '32px 40px' }}>
          <Title level={3}>광고 등록하기</Title>
        </div>
      }
      footer={
        <Button
          type="primary"
          size="large"
          disabled={!selectedAccount || !selectedDate.startDate || !selectedGoods.id}
          onClick={nextStep}
        >
          다음
        </Button>
      }
    >
      <div className={styles.container}>
        <div>
          <HeadNotify />
          <div className={styles.goodsContainer}>
            {accountList && selectedAccount?.name ? (
              <>
                <Group value={selectedAccount} className={styles.accountList}>
                  {accountList.map((account) => (
                    <Radio
                      value={account}
                      key={account.areas[0].id}
                      className={cx(
                        styles.goodsBtn,
                        account.areas[0].available === 1 && styles.disabled,
                        selectedAccount.areas[0] === account.areas[0] && styles.active,
                        hiddenListForUser(account.areas[0].id as AreaIDType) && styles.hidden,
                        account.areas[0].id === AreaType.mainContentB && styles.hidden // 아예 안보이도록 설정
                      )}
                      disabled={account.areas[0].available === 1 || account.areas[0].id === AreaType.mainContentB}
                      onChange={(event) => handleAccount(event.target.value)}
                    >
                      {account.name} {account.subName}
                    </Radio>
                  ))}
                </Group>
                <div className={styles.carousel}>
                  {selectedAccount && (
                    <Slider {...settings}>
                      {selectedAccount.areas.map((item) => (
                        <div key={item.id} className={styles.subImageContainer}>
                          <img src={item.imgUrl} alt={item.area} key={item.id} />
                          <p className={styles.carouselText}>
                            {item.area}
                            <br />
                            {(selectedAccount.areas[0].id === AreaType.mainContentA ||
                              selectedAccount.areas[0].id === AreaType.mainContentB ||
                              selectedAccount.areas[0].id === AreaType.mainContentC ||
                              selectedAccount.areas[0].id === AreaType.mainBoard) &&
                              '(Mobile only)'}
                          </p>
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
              </>
            ) : (
              <LoadingBar />
            )}
          </div>
          <MiddleNotify />
        </div>
      </div>
      <div className={styles.adGuide}>
        {selectedAccount?.name && <AdGuide url={selectedAccount.areas[0].manualUrl} />}
      </div>
      <Divider />
      <div className={styles.selectArea}>
        {goodsList && Array.isArray(goodsList.result) && (
          <div className={styles.goodsArea}>
            <SelectGoods goodsList={goodsList.result} onSelect={handleGoods} />
          </div>
        )}
        <SelectDate
          selectedGoods={selectedGoods}
          selecedDate={selectedDate}
          businessDays={businessDays?.result || []}
          onSelect={handleDate}
          isSuperUser={isSuperUser}
        />
        <p style={{ marginTop: 12, height: 22 }}>
          {selectedGoods.id && selectedDate.startDate && (
            <>
              평일: {checkHolidays.normalDays}일 - {numberFormat(selectedGoods.price * checkHolidays.normalDays) || 0}원
              / 휴일: {checkHolidays.holidays}일 -{' '}
              {numberFormat(selectedGoods.holidayPrice * checkHolidays.holidays) || 0}원
            </>
          )}
        </p>
        <p style={{ paddingTop: '16px', height: 22 }}>
          <br />
          {selectedGoods.id && selectedDate.startDate === '2024-04-01' && (
            <span
              style={{
                marginTop: '16px',
                background: '#FFF1F1',
                borderRadius: '8px',
                padding: '16px',
                color: '#495057',
              }}
            >
              <ExclamationCircleFilled style={{ color: '#FF6666', marginRight: '8px' }} />
              {'24.04.01(월) 와디즈 서비스 점검으로 AM 03:15 ~ 05:15 (최대 2시간) 광고 노출 어려운 점 참고해주세요'}
            </span>
          )}
        </p>
      </div>
    </Modal>
  )
}
