import React from 'react'
import { PersonIcon } from '@wadiz/waffle-icons'
import { getAreaStyle, AreaType } from '@common'

import { connect } from 'react-redux'
import { openSecondPopup, closeSecondPopup } from '../../reducers/secondDialogReducer'
import className from 'classnames/bind'
import style from './PreviewDialog.module.scss'

const cx = className.bind(style)

const printContent = (content) => {
  const tmpContent = content.split('\n')

  return tmpContent.map((val, idx) => (
    <>
      {idx !== 0 && <br />}
      {val}
    </>
  ))
}

const CloseButtonArea = ({ onClose }) => (
  <div className={cx('dialog_close')}>
    <button className={cx('wz button less')} onClick={onClose}>
      <i className={cx('icon close')}></i>
    </button>
  </div>
)

const ToggleSize = ({ isMobile, doToggle }) => (
  <div className={cx('dialog_switchSize')}>
    <button className={cx('wz button primary-outline mini', { active: !isMobile })} onClick={() => doToggle(false)}>
      PC
    </button>
    <button className={cx('wz button primary-outline mini', { active: isMobile })} onClick={() => doToggle(true)}>
      MOBILE
    </button>
  </div>
)
const OnlyTab = ({ onlyTab }) => (
  <div className={cx('dialog_switchSize')}>
    <button className={cx('wz button primary-outline mini', { active: true })}>{onlyTab}</button>
  </div>
)
const DialogHeader = ({ children, onClose, isMin, changeView, onlyTab }) => (
  <header>
    <div className={cx('headerTools')}>
      {!onlyTab ? <ToggleSize isMobile={isMin} doToggle={changeView} /> : <OnlyTab onlyTab={onlyTab} />}
      <CloseButtonArea onClose={onClose} />
    </div>
    <div className={cx('dialog_title')}>{children}</div>
  </header>
)

const DialogFrame = ({ adClass, isMin, selClass, onClose, changeView, title, children, onlyTab }) => (
  <div className={cx('dialog_shadow')} style={{ zIndex: 100 }}>
    <div className={cx('dialog_wrap', 'previewImg', `previewImg-${adClass}`, { isMin }, selClass)}>
      <DialogHeader onClose={onClose} isMin={isMin} changeView={changeView} onlyTab={onlyTab}>
        <h3>{title}</h3>
      </DialogHeader>
      <main className={cx('dialog_content')}>{children}</main>
    </div>
  </div>
)

const ContentImage = ({ imgUrl, bgColor, isMin, isShowAD }) => {
  const styleAdImage = {
    backgroundImage: `url(${imgUrl})`,
  }
  const styleImg = bgColor ? { backgroundColor: bgColor } : {}
  return (
    <div className={cx('preview_content_img', isMin && 'isMin')} style={styleImg}>
      <span
        className={cx('preview_content_background')}
        style={styleAdImage}
        aria-label={'광고 이름 입력해주세요'}
      ></span>
      {isShowAD && <span className={cx('preview_content_ad', isMin && 'isMin')}>AD</span>}
    </div>
  )
  // <div className={cx('preview_content_img')} style={{ backgroundColor: bgColor }}>
  //   <span className={cx('preview_content_ad')}>AD</span>
  //   <img src={imgUrl && imgUrl !== '' ? imgUrl : noimage} alt="광고 이름 입력해주세요" />
  // </div>
}

const PreviewDialog = ({ secondDialog: { dialogType, dialogData }, closePopup }) => {
  const isShow = dialogType === 'previewDialog'
  const [isMin, setMin] = React.useState(false)
  const { title, imgUrl, mainCopy, subCopy, bgColor, pubAreaId, lossText, projectType } = dialogData

  const { adClass, selClass } = getAreaStyle(pubAreaId) || {}

  if (!adClass || !selClass) {
    return null
  }

  return (
    isShow && (
      <DialogFrame
        adClass={adClass}
        isMin={isMin}
        selClass={selClass}
        onClose={closePopup}
        changeView={(state) => setMin(state)}
        title={title}
        onlyTab={adClass === 'Key' && selClass === 'isMain' ? 'PC Only' : null}
      >
        <ContentImage
          imgUrl={imgUrl}
          bgColor={adClass !== 'line' ? '' : bgColor}
          isMin={isMin}
          isShowAD={adClass !== 'Key' && adClass !== 'line' && adClass !== 'Main'}
        />
        {adClass === 'Key' && (
          <div className={cx('keyBannerContent')}>
            <div className={cx('preview_content', isMin && 'isMin')}>
              <div className={cx('keyBannerCopyContainer', isMin && 'isMin')}>
                {lossText && projectType === 1 && pubAreaId !== AreaType.storeList && (
                  <p className={cx('preview_content_lossText')}>{lossText}</p>
                )}
                <div className={cx('keyBannerMainCopy', isMin && 'isMin')}>{printContent(mainCopy)}</div>
                <div className={cx('keyBannerSubCopy', isMin && 'isMin')}>
                  {pubAreaId !== AreaType.mainHome
                    ? printContent(subCopy)
                    : projectType === 1
                      ? '투자'
                      : projectType === 2
                        ? '펀딩'
                        : projectType === 4
                          ? '프리오더'
                          : '스토어'}
                </div>
              </div>
            </div>
            <div className={cx('keyBannerActiveBar', isMin && 'isMin')}>
              <div></div>
            </div>
          </div>
        )}
        {adClass === 'line' && (
          <div className={cx('preview_content', isMin && 'isMin')}>
            <div className={cx('preview_content_subCopy')}>{printContent(subCopy)}</div>
            <div className={cx('preview_content_mainCopy')}>{printContent(mainCopy)}</div>
          </div>
        )}
        {adClass === 'project' && (
          <div className={cx('preview_content')}>
            <div className={cx('preview_fixed', 'fixed_invest', 'preview_content_cat')}>
              <span className={cx('preview_content_opening')}>펀딩중</span>
              <em>증권유형</em>카테고리
            </div>
            <div className={cx('preview_fixed', 'fixed_invest')}>
              <div className={cx('preview_content_processing')}>
                <strong className={cx('preview_content_percent')}>50%</strong>
                <span className={cx('preview_content_increase')}>증액예정</span>
                <em className={cx('preview_content_amount')}>1,000,000원</em>{' '}
              </div>
              <div className={cx('preview_content_subInfo')}>
                <span>메이커명</span>
                <em className={cx('preview_content_dayLeft')}>3일 남음</em>
              </div>
            </div>
            <div className={cx('preview_fixed', 'fixed_rw')}>
              <article>
                <p className={cx('preview_content_mainCopy')}>
                  <strong>타이틀 영역입니다.</strong>
                </p>
                <div className={cx('preview_content_subInfo')}>
                  <span>카테고리</span>
                  <span>메이커명</span>
                </div>
              </article>
              <div className={cx('preview_content_processing')}>
                <div className={cx('preview_content_gauge')}>
                  <span></span>
                </div>
                <section>
                  <span>
                    <strong className={cx('preview_content_percent')}>50%</strong>
                    <em className={cx('preview_content_amount')}>1,000,000원</em>
                  </span>
                  <em className={cx('preview_content_dayLeft')}>3일 남음</em>
                </section>
              </div>
            </div>
            <div className={cx('preview_fixed', 'fixed_coming')}>
              <em className={cx('preview_content_coming')}>오픈예정</em>
              <span className={cx('preview_content_subInfo')}>메이커명</span>
            </div>
            <p className={cx('preview_content_subCopy')}>{printContent(subCopy)}</p>
            <button className={cx('wz button primary-outline mini')}>보러가기</button>
          </div>
        )}
        {adClass === 'store' && (
          <div className={cx('preview_content', isMin && 'isMin')}>
            <div className={cx('fundingSucceeded')}>1,000,000원 펀딩 성공</div>
            <div className={cx('title')}>타이틀 영역입니다.</div>
            <div className={cx('maker')}>메이커 명</div>
            <div className={cx('amount')}>
              <em>10,000</em>원~
            </div>
            <div className={cx('account')}>
              <PersonIcon /> 100명 참여
            </div>
          </div>
        )}
        {adClass === 'Main' && (
          <div className={cx('preview_content')} style={{ backgroundColor: bgColor ? bgColor : '#FFF' }}>
            <div className={cx('preview_content_copy')}>{printContent(mainCopy)}</div>
            <div className={cx('preview_content_copy')}>{printContent(subCopy)}</div>
            <img src={imgUrl} alt="" />
          </div>
        )}
      </DialogFrame>
    )
  )
}

export default connect(({ secondDialog }) => ({ secondDialog }), {
  openPopup: openSecondPopup,
  closePopup: closeSecondPopup,
})(PreviewDialog)
