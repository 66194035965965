import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom-v5'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'

import { openPopup, openAlertPopup, setMenuStatus, openWaffleModal } from '../../reducers'
import { sortByArea, AreaType, checkMakerLandingURL } from '@common'
import { TermsModalContainer } from '../../components/TermsModalContainer'

import AdModifyContent from '../../components/AdModifyContent2/AdModifyContent'
import { network } from '../../network'
// import className from 'classnames/bind'
import { centerGetPubAreaAvailability } from '@wad/api'
import { getTermsAgreements, getWadizOnlyCondition } from '../_api'
import { fetchBusinessDays, fetchBgColorList } from '@center/api'

// import style from '../../components/AdModifyContent2/AdModifyContent.module.scss'
// const cx = className.bind(style)
class AdModifyPage extends PureComponent {
  selectedAdAccountCardRef = React.createRef()

  state = {
    isModify: true,
    modifyStep: 1,
    isComplete: false,
    makerId: 0,
    projectId: 0,
    projectName: '',
    adId: 0,
    adName: '',
    LnbData: [],
    adAccountList: [],
    projectList: [],
    // bgColorList: [],
    openGuide: { idx: -1, type: '' },

    adsPrice: 0, // 전체 광고 금액
    adsVat: 0, // 전체 부가세
    totalPrice: 0, // 총 금액

    startTime: '',

    modifiedAdAccountList: [],
    landingUrl: '',
    selectProjectErrMsg: '',
    changeAdNameErrMsg: '',

    selectedAdAccountCardId: 0,
    openDate: '',

    projectType: 0,
    lossText: '',

    loadingBarFlag: false,

    usingCouponList: [],

    // 약관 동의 모달
    isOpenAgreementModal: false,

    holidays: [],
    shared: null,
  }

  componentDidMount() {
    this.getAdModifyData()
    if (this.props.user.isWadizAdmin) this.setState({ isComplete: true })
  }

  static getDerivedStateFromProps = (nextProps, nextState) => {
    const { pathname } = window.location
    const step = pathname.split('/')[5]
    switch (step) {
      case 'step1':
        return { ...nextState, modifyStep: 1 }
      case 'step2':
        return { ...nextState, modifyStep: 2 }
      default:
        return { ...nextState, modifyStep: 1 }
    }
  }

  async getHolidays() {
    const { result } = await fetchBusinessDays({ startDate: dayjs().format('YYYY-MM-DD'), withinDays: 60 })
    this.setState({
      // o 제외가 휴일
      holidays: result.filter((item) => item.type !== 'o'),
    })
  }

  getMakerInfo = () => {
    const { projectId, adAccountList } = this.props.history.location.state
    // 메이커 정보 가져오기 - LandingURL
    if (checkMakerLandingURL(adAccountList[0]?.adCenterPubAreaId)) {
      new Promise((resolve, reject) =>
        network.callApi({
          url: `maker-page/info?project_id=${projectId}&creative_id=${adAccountList[0].id}`,
          method: 'get',
          successFunc: (jsonData) => {
            this.setState({
              makerInfo: jsonData.result,
            })
            resolve(jsonData.result)
          },
          failFunc: (error) => reject(error),
        })
      )
    }
  }

  async getAdModifyData() {
    await this.getHolidays()
    this.getMakerInfo()

    this.setState({ loadingBarFlag: true }, () => {
      let projectList = [...this.state.projectList]
      // let bgColorList = [...this.state.bgColorList]
      let adAccountList = [...this.state.adAccountList]
      const usingCouponList = [...this.state.usingCouponList]
      const modifiedAdAccountList = [...this.state.modifiedAdAccountList]
      const promiseList = []

      let { landingUrl, comingsoon } = this.state

      const { campaignId, projectId, projectName, LnbData, adId, adName, openDate, projectType, lossText } =
        this.props.history.location.state

      let addedAccountList = []
      const addedPromiseList = []
      const addedPromise = new Promise((addedResolve, addedReject) => {
        if (this.props.history.location.addedAccountList) {
          addedAccountList = [...this.props.history.location.addedAccountList]
          addedAccountList.forEach((adAccount) => {
            const tempAdAccount = adAccount
            const adCenterPubAccountId = tempAdAccount.id
            addedPromiseList.push(
              new Promise((resolve, reject) => {
                return network.callApi({
                  url: `pub-account/info?pub_account_id=${adCenterPubAccountId}`,
                  method: 'get',
                  successFunc: (jsonData) => {
                    tempAdAccount.info = jsonData.result
                    resolve(jsonData.result)
                  },
                  failFunc: (error) => {
                    reject(error)
                    addedReject(error)
                  },
                })
              })
            )
            addedPromiseList.push(
              new Promise((resolve, reject) => {
                const url =
                  tempAdAccount.usedDiscount === 1
                    ? `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${adCenterPubAccountId}&is_used=1`
                    : `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${adCenterPubAccountId}`
                return network.callApi({
                  url,
                  method: 'get',
                  successFunc: (discountCouponData) => {
                    if (discountCouponData.result.list && discountCouponData.result.list.length > 0) {
                      const discountCoupon = [...discountCouponData.result.list]
                      if (tempAdAccount.usedDiscount === 2) {
                        for (let i = 0; i < discountCoupon.length; i += 1) {
                          if (
                            discountCoupon[i].isUsed === 2 &&
                            tempAdAccount.adCenterDiscountId &&
                            tempAdAccount.adCenterDiscountId !== discountCoupon[i].id
                          ) {
                            discountCoupon.splice(i, 1)
                          }
                        }
                      }
                      tempAdAccount.discountCoupon = discountCoupon
                    } else {
                      tempAdAccount.discountCoupon = []
                    }
                    resolve()
                  },
                  failFunc: (error) => {
                    reject(error)
                    addedReject(error)
                  },
                })
              })
            )
          })
          Promise.all(addedPromiseList).then(() => {
            addedAccountList.forEach((adAccount) => {
              const tempAdAccount = adAccount
              tempAdAccount.adCenterAdvertisementId = this.state.adId
              tempAdAccount.adCenterPubAreaId = tempAdAccount.info.adCenterPubAreaId
              tempAdAccount.adCenterPubAccountId = tempAdAccount.id
              tempAdAccount.area = tempAdAccount.positionName
              tempAdAccount.status = 1
              tempAdAccount.adProcessStatus = 0
              tempAdAccount.adPayStatus = 0
              tempAdAccount.callApiType = 'create'
              tempAdAccount.landingUrl = landingUrl
              tempAdAccount.mainCopy = ''
              tempAdAccount.keyword = ''
              tempAdAccount.subCopy = ''
              if (tempAdAccount.discountCoupon.length > 0) {
                if (adAccount.usedDiscount === 2) {
                  usingCouponList.push(tempAdAccount.adCenterDiscountId)
                  tempAdAccount.discountIdx = tempAdAccount.discountCoupon.findIndex(
                    (coupon) => coupon.id === tempAdAccount.adCenterDiscountId
                  )
                  tempAdAccount.discountPrice =
                    tempAdAccount.price - (tempAdAccount.price * tempAdAccount.discountCoupon[0].discountRate) / 100
                } else {
                  tempAdAccount.discountIdx = ''
                }
              }
              addedResolve()
            })
          })
        } else {
          addedResolve()
        }
      })
      addedPromise.then(
        () => {
          let { selectedAdAccountCardId } = { ...this.props.history.location }

          selectedAdAccountCardId = selectedAdAccountCardId || 0

          network.callApi({
            url: `creative/list?advertisement_id=${adId}`,
            method: 'get',
            successFunc: (jsonData) => {
              adAccountList = []
              adAccountList = sortByArea(
                jsonData.result.sort((a, b) => {
                  return Number(b.main) - Number(a.main) // 메인 영역인 값을 index 0으로 만들기
                }),
                this.props.user.isSuperUser
              )

              addedAccountList.forEach((addedAccount) => {
                adAccountList.unshift(addedAccount)
                modifiedAdAccountList.unshift(addedAccount)
              })

              this.setState({
                shared: jsonData.result[0].shared,
              })
              const getLandingurl = new Promise((resolve, reject) => {
                return network.callApi({
                  url: `landing-url/info?project_id=${projectId}`,
                  method: 'get',
                  successFunc: (landingUrlData) => {
                    landingUrl = Object.prototype.hasOwnProperty.call(landingUrlData.result, 'landingUrl')
                      ? landingUrlData.result.landingUrl
                      : ''
                    comingsoon = Object.prototype.hasOwnProperty.call(landingUrlData.result, 'comingsoon')
                      ? landingUrlData.result.comingsoon
                      : ''
                    resolve(landingUrl)
                  },
                  failFunc: (landingUrlError) => {
                    reject(landingUrlError)
                  },
                })
              })

              promiseList.push(getLandingurl)

              const getProjectList = new Promise((resolve, reject) => {
                return network.callApi({
                  url: `projects/list?maker_id=${this.props.user.maker_id}`,
                  method: 'get',
                  header: { 'Content-Type': 'application/json;charset=UTF-8' },
                  successFunc: (projectListData) => {
                    projectList = projectListData.result
                    resolve(projectListData.result)
                  },
                  failFunc: (error) => {
                    reject(error)
                  },
                })
              })

              promiseList.push(getProjectList)

              adAccountList.forEach((adAccount) => {
                const tempAdAccount = adAccount
                let selectType = tempAdAccount.packagedId ? 2 : 0

                // 슈퍼유저일 경우 다중지면도 패키지가 아닌 단일로 해야함 type: 0 - 일반 / 2 - 패키지
                // if (this.props.user.isSuperUser) selectType = 0;
                promiseList.push(
                  new Promise((resolve, reject) => {
                    return network.callApi({
                      url: `goods/list?pub_account_id=${tempAdAccount.adCenterPubAccountId}&advertiser_type=${
                        this.props.user.isWadizAdmin ? 1 : 0
                      }&type=${selectType}&${
                        projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'
                      }=${campaignId}`,
                      method: 'get',
                      successFunc: (goodsListData) => {
                        tempAdAccount.goodsList = goodsListData.result === 'fail' ? [] : goodsListData.result
                        for (let j = 0; j < goodsListData.result.length; j += 1) {
                          if (goodsListData.result[j].id === tempAdAccount.adCenterPubGoodsId) {
                            tempAdAccount.selectedGoods = goodsListData.result[j]
                            break
                          }
                        }
                        resolve(goodsListData.result)
                      },
                      failFunc: (error) => {
                        reject(error)
                      },
                    })
                  })
                )

                promiseList.push(
                  new Promise((resolve, reject) => {
                    return network.callApi({
                      url: `pub-account/info?pub_account_id=${tempAdAccount.adCenterPubAccountId}`,
                      method: 'get',
                      successFunc: (accountInfoData) => {
                        tempAdAccount.info = accountInfoData.result
                        resolve(accountInfoData.result)
                      },
                      failFunc: (error) => {
                        reject(error)
                      },
                    })
                  })
                )

                promiseList.push(
                  new Promise((resolve, reject) => {
                    const url =
                      tempAdAccount.usedDiscount === 1
                        ? `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${tempAdAccount.adCenterPubAccountId}&is_used=1`
                        : `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${tempAdAccount.adCenterPubAccountId}`
                    return network.callApi({
                      url,
                      method: 'get',
                      successFunc: (discountCouponData) => {
                        if (discountCouponData.result.list && discountCouponData.result.list.length > 0) {
                          const discountCoupon = [...discountCouponData.result.list]
                          if (tempAdAccount.usedDiscount === 2) {
                            for (let i = 0; i < discountCoupon.length; i += 1) {
                              if (
                                discountCoupon[i].isUsed === 2 &&
                                tempAdAccount.adCenterDiscountId &&
                                tempAdAccount.adCenterDiscountId !== discountCoupon[i].id
                              ) {
                                discountCoupon.splice(i, 1)
                              }
                            }
                          }
                          tempAdAccount.discountCoupon = discountCoupon
                        } else {
                          tempAdAccount.discountCoupon = []
                        }
                        resolve()
                      },
                      failFunc: (error) => {
                        reject(error)
                      },
                    })
                  })
                )
              })

              const checkMigration = (tempAdAccount) => {
                return new Promise((resolve, reject) => {
                  return network.callApi({
                    url: `goods/list?pub_account_id=${tempAdAccount.adCenterPubAccountId}&advertiser_type=${
                      this.props.user.isWadizAdmin ? 1 : 0
                    }&type=0&${projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'}=${campaignId}`,
                    method: 'get',
                    successFunc: (res) => {
                      tempAdAccount.goodsList = res.result === 'fail' ? [] : res.result
                      for (let j = 0; j < res.result.length; j += 1) {
                        if (res.result[j].id === tempAdAccount.adCenterPubGoodsId) {
                          tempAdAccount.selectedGoods = res.result[j]
                          break
                        }
                      }
                      resolve(tempAdAccount)
                    },
                  })
                })
              }

              // 가격
              let adsPrice = 0
              let adsVat = 0
              let totalPrice = 0

              Promise.all(promiseList).then(async () => {
                adAccountList.forEach(async (adAccount) => {
                  let tempAdAccount = adAccount

                  tempAdAccount.selectedGoods = tempAdAccount.selectedGoods || {
                    impPeriod: 0,
                    impNum: 0,
                    price: 0,
                  }

                  tempAdAccount.startAt = tempAdAccount.startAt ? tempAdAccount.startAt : ''
                  tempAdAccount.endAt = tempAdAccount.endAt ? tempAdAccount.endAt : ''
                  tempAdAccount.startTime = tempAdAccount.startAt.substring(11, 16)
                    ? tempAdAccount.startAt.substring(11, 16)
                    : this.state.startTime
                  tempAdAccount.impNum = tempAdAccount.selectedGoods.impNum
                  tempAdAccount.pricingType = tempAdAccount.selectedGoods.pricingType
                  tempAdAccount.impPeriod = tempAdAccount.selectedGoods.impPeriod
                  tempAdAccount.impMinPeriod = tempAdAccount.selectedGoods.impMinPeriod

                  tempAdAccount.positionName = tempAdAccount.area
                  tempAdAccount.adCenterPubAreaId = tempAdAccount.info.adCenterPubAreaId
                  tempAdAccount.landingUrl = tempAdAccount.landingUrl ? tempAdAccount.landingUrl : landingUrl
                  tempAdAccount.goodsIdx = tempAdAccount.goodsList?.findIndex(
                    (goods) => goods.id === tempAdAccount.selectedGoods.id
                  )
                  tempAdAccount.pricingType = tempAdAccount.info.pricingType

                  // find discount coupon list index
                  if (tempAdAccount.discountCoupon.length > 0) {
                    if (tempAdAccount.usedDiscount === 2) {
                      usingCouponList.push(tempAdAccount.adCenterDiscountId)
                      tempAdAccount.discountIdx = tempAdAccount.discountCoupon.findIndex(
                        (coupon) => coupon.id === tempAdAccount.adCenterDiscountId
                      )
                      tempAdAccount.discountIdx = tempAdAccount.discountIdx < 0 ? '' : tempAdAccount.discountIdx
                      tempAdAccount.discountPrice = tempAdAccount.price
                      tempAdAccount.price = tempAdAccount.selectedGoods.price
                      tempAdAccount.vat = Math.floor(tempAdAccount.discountPrice * 0.1)
                    } else {
                      tempAdAccount.discountIdx = ''
                      tempAdAccount.vat = Math.floor(tempAdAccount.price * 0.1)
                    }
                  } else {
                    tempAdAccount.discountPrice = null
                  }
                  // TODO : 상태에 따라서 전이 할 수 있도록 관리 작업이 필요함 .
                  if (
                    this.props.user.isWadizAdmin ||
                    (tempAdAccount.status === 1 && tempAdAccount.adProcessStatus !== 3) ||
                    tempAdAccount.status === 4 ||
                    tempAdAccount.adState === 'Accepted' ||
                    tempAdAccount.adState === 'Payment_Failed' ||
                    tempAdAccount.adState === 'Modified_in_Contr' ||
                    tempAdAccount.adState === 'Modified_in_Failed'
                  ) {
                    if (!tempAdAccount.callApiType) {
                      tempAdAccount.callApiType = 'update'
                    }
                    modifiedAdAccountList.push(tempAdAccount)
                  }
                })

                adAccountList = await Promise.all(
                  adAccountList.map(async (adAccount) => {
                    // 마이그레이션 시 나오는 문제 방어코드
                    if (adAccount.goodsIdx === -1 && adAccount.packagedId) {
                      const newData = await checkMigration(adAccount)
                      adAccount = {
                        ...adAccount,
                        ...newData,
                      }
                      adAccount.goodsIdx = adAccount.goodsList?.findIndex(
                        (goods) => goods.id === adAccount.selectedGoods.id
                      )
                    }

                    // bgColor를 사용할 때 API 호출
                    if (adAccount.bgColor) {
                      const { result } = await fetchBgColorList(adAccount.info.adCenterPubAreaId)
                      adAccount.bgColorList = result
                      adAccount.adCenterBgColorId =
                        result[result.findIndex((bg) => bg.bgColor === adAccount.bgColor)].adCenterPubAreaId
                    }

                    // 휴일 단가
                    let normalDays = 0,
                      holidays = 0
                    // 휴일, 평일 체크
                    const dateRange = new Array(adAccount.impMinPeriod)
                      .fill('')
                      .map((_, idx) => dayjs(adAccount.startAt).add(idx, 'day').format('YYYY-MM-DD'))
                    dateRange.forEach((date) => {
                      if (this.state.holidays.some((i) => date === i.eventDate)) {
                        holidays += 1
                      } else {
                        normalDays += 1
                      }
                    })
                    const price =
                      (adAccount.selectedGoods.price * normalDays || 0) +
                      (adAccount.selectedGoods.holidayPrice * holidays || 0)
                    adsPrice += price
                    adsVat += Math.floor(price * 0.1)
                    adAccount.normalDays = normalDays
                    adAccount.holidays = holidays
                    return adAccount
                  })
                )

                totalPrice = adsPrice + adsVat
                const tempAdAccountList = []
                const tempIndexArray = []
                for (let i = 0; i < adAccountList.length; i += 1) {
                  if (adAccountList[i].status === 1) {
                    tempAdAccountList.push(adAccountList[i])
                    tempIndexArray.push(i)
                  }
                }

                for (let i = 0; i < adAccountList.length; i += 1) {
                  if (tempIndexArray.indexOf(i) < 0) {
                    tempAdAccountList.push(adAccountList[i])
                  }
                }

                adAccountList = tempAdAccountList

                this.setState(
                  {
                    campaignId,
                    projectId,
                    projectName,
                    LnbData,
                    adId,
                    adName,
                    adAccountList,
                    projectList,
                    // bgColorList,
                    adsPrice,
                    adsVat,
                    totalPrice,
                    modifiedAdAccountList,
                    selectedAdAccountCardId,
                    startTime: adAccountList[0] ? adAccountList[0].startTime : '00:00',
                    landingUrl,
                    comingsoon,
                    openDate,
                    lossText,
                    projectType,
                    loadingBarFlag: false,
                    usingCouponList,
                  },
                  () => {
                    this.scrollToAdAccountCardTop()
                  }
                )
              })
            },
            failFunc: () => {
              this.setState({ loadingBarFlag: false })
            },
          })
        },
        () => {
          this.setState({ loadingBarFlag: false })
        }
      )
    })
  }

  handleOpenGuideType = (idx, type) => {
    this.setState((prevState) => ({ openGuide: { idx, type: prevState.openGuide.type === type ? '' : type } }))
  }

  handleChangeProject = (event) => {
    const prjectList = [...this.state.projectList]
    const projectId = Number(event.target.value)
    const { projectName } = prjectList[prjectList.findIndex((project) => project.id === projectId)]
    this.setState({ projectId, projectName, selectProjectErrMsg: '' })
  }

  handleChangeAdName = (event) => {
    this.setState({ adName: event.target.value, changeAdNameErrMsg: '' })
  }

  handleAdAccountModalOpen = () => {
    const getCheckedNotBlocked = (makerId) =>
      new Promise((resolve, reject) =>
        network.callApi({
          url: `maker/isBlocked?maker_id=${makerId}`,
          method: 'get',
          successFunc: (jsonData) => resolve(jsonData ? jsonData.result : false),
          failFunc: () => reject(),
        })
      )

    const { openDate, campaignId, projectType, comingsoon, landingUrl } = this.state

    const getGoodListData = (id, isWadizAdmin, isPackaged) => {
      let selectType = isPackaged ? 2 : 0

      // 슈퍼유저일 경우 다중지면도 패키지가 아닌 단일로 해야함 type: 0 - 일반 / 2 - 패키지
      // if (this.props.user.isSuperUser) selectType = 0

      return new Promise((resolve, reject) =>
        network.callApi({
          url: `goods/list?pub_account_id=${id}&advertiser_type=${isWadizAdmin ? '1' : '0'}&type=${selectType}&${
            projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'
          }=${campaignId}`,
          method: 'get',
          successFunc: (jsonData) => resolve(jsonData.result),
          failFunc: (error) => reject(error),
        })
      )
    }

    const getPubAccountInfoData = (id) =>
      new Promise((resolve, reject) =>
        network.callApi({
          url: `pub-account/info?pub_account_id=${id}`,
          method: 'get',
          successFunc: (jsonData) => {
            resolve(jsonData.result)
          },
          failFunc: (error) => {
            reject(error)
          },
        })
      )

    const getDiscountCouponCode = (id, usedDiscount, adCenterDiscountId) =>
      new Promise((resolve, reject) =>
        network.callApi({
          url:
            usedDiscount === 1
              ? `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${id}&is_used=1`
              : `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${id}`,
          method: 'get',
          successFunc: (jsonData) => {
            resolve(
              !jsonData.result.list
                ? []
                : usedDiscount !== 2
                ? jsonData.result.list
                : jsonData.result.list.filter(
                    (item) => item.isUsed !== 2 || !adCenterDiscountId || adCenterDiscountId === item.id
                  )
            )
          },
          failFunc: (error) => {
            reject(error)
          },
        })
      )

    const confirmFunc = (data) => {
      const aList = data.returnState.accountList.reduce((acc, item) => {
        if (!item.packagedInfo) {
          return [...acc, item]
        }

        const {
          discount,
          endAt,
          goodsIdx,
          goodsList,
          goodsNum,
          isAdded,
          startAt,
          packagedInfo: { accountItem, goodsItem, positionName, manualUrl },
        } = item

        return [
          ...acc,
          {
            ...item,
            price: goodsList[goodsIdx].price,
            packaged: 'master',
          },
          {
            ...accountItem,
            price: goodsItem[goodsIdx].price,
            discount,
            endAt,
            goodsIdx,
            goodsList,
            goodsNum,
            goodsid: goodsItem[goodsIdx].id,
            isAdded,
            manualUrl,
            positionName,
            selectedGoods: goodsItem[goodsIdx],
            startAt,
            packaged: 'slave',
          },
        ]
      }, [])

      const makeAccountItem = (base, goodsList, info, discountCoupon) => {
        const newAccountItem = {
          ...base,
          goodsList,
          openDate: dayjs(data.returnState.startDate).format('YYYY-MM-DD'),
          mainCopy: '',
          keyword: '',
          subCopy: '',
          landingUrl,
          info,
          adCenterPubAccountId: base.id,
          vat: Math.floor(base.price * 0.1),
          discountCoupon,
          adStartDateId: `adStartDate_${base.id}`,
          adEndDateId: `adEndDate_${base.id}`,
          adCenterAdvertisementId: this.state.adId,
          adCenterPubAreaId: info.adCenterPubAreaId,
          area: base.positionName,
          status: 1,
          adProcessStatus: 0,
          adPayStatus: 0,
          callApiType: 'create',
          pricingType: info.pricingType,
        }

        if (discountCoupon.length > 0) {
          newAccountItem.discountIdx = 0
          newAccountItem.discountId = newAccountItem.discountCoupon[newAccountItem.discountIdx].id
          newAccountItem.discountPrice =
            newAccountItem.price - newAccountItem.price * newAccountItem.discountCoupon[0].discountRate
        } else {
          newAccountItem.discountPrice = null
        }
        return newAccountItem
      }

      Promise.all(
        aList.reduce(
          (acc, item) => [
            ...acc,
            getGoodListData(item.id, this.props.user.isWadizAdmin, item.packaged),
            getPubAccountInfoData(item.id),
            getDiscountCouponCode(item.id, item.usedDiscount, item.adCenterDiscountId),
          ],
          []
        )
      ).then((response) => {
        const len = response.length / 3
        const tmpList = []
        for (let i = 0; i < len; i += 1) {
          const baseIdx = i * 3
          tmpList.push(makeAccountItem(aList[i], response[baseIdx], response[baseIdx + 1], response[baseIdx + 2]))
        }

        const { adAccountList: preAccountList, modifiedAdAccountList: preModified } = this.state
        const adAccountList = sortByArea([...tmpList, ...preAccountList])
        const modifiedAdAccountList = sortByArea([...tmpList, ...preModified])

        const prices = adAccountList.reduce(
          (a, adAccount) => {
            const price =
              (adAccount.price * adAccount.normalPeriod || 0) + (adAccount.holidayPrice * adAccount.holidayPeriod || 0)

            return {
              adsPrice: a.adsPrice + price,
              adsVat: a.adsVat + Math.floor(price * 0.1),
            }
          },
          { adsPrice: 0, adsVat: 0 }
        )

        this.setState(
          {
            adAccountList,
            modifiedAdAccountList,
            totalPrice: prices.adsPrice + prices.adsVat,
            ...prices,
          },
          () => {
            this.scrollToAdAccountCardTop()
          }
        )
      })
    }

    getCheckedNotBlocked(this.props.user.userData.makerId)
      .then((isNotBlocked) => {
        if (isNotBlocked) {
          return Promise.resolve(true)
        }
        this.props.openAlertPopup({
          alertData: {
            content: (
              <p>
                메이커님은 관리자에 의해 차단되었습니다. 관리자에게 문의 바랍니다.
                <br />
                ad@wadiz.kr로 메일 주시기 바랍니다.
              </p>
            ),
            closeText: '닫기',
          },
        })
        return Promise.reject()
      })
      .then(() => {
        this.props.openPopup({
          dialogType: 'adAccount',
          dialogData: {
            isStoreProject: this.isStoreProject(),
            campaignId,
            openDate,
            projectType,
            comingsoon,
            accountList: [...this.state.adAccountList],
            confirmFunc,
          },
        })
      })
  }

  isWadizOnlyGoodsSelected = () => {
    const { adAccountList: accountList } = this.state
    // accountList 는 상품 정보를 가지고 있는 배열이다.
    // FE레거시 코드가 이부분을 굉장히 복잡하게 관리하고 있는데,
    // 어쨌든 store에 경우 상품을 하나씩만 선택가능하므로 wadiz Only를 확인하는 대상은 0번 index일 수 밖에없다.
    if (!accountList[0]) {
      return false
    }
    const { selectedGoods } = accountList[0]
    return selectedGoods?.badgeType === 'WadizOnly'
  }

  isStoreProject = () => {
    const { projectList, campaignId } = this.state
    return projectList?.find((p) => `${p.campaignId}` === `${campaignId}`)?.projectType === 3
  }

  adAccountListDel = (idx) => {
    this.props.openPopup({
      dialogType: 'adAccountConfirmDialog',
      dialogData: {
        type: 'delete',
        title: '만들고 있던 광고 상품을 삭제 하시겠습니까?',
        confirmText: '삭제',
        // confirmFunc: this.deleteAdAccount,
        confirmFunc: () => {
          const adAccountList = [...this.state.adAccountList]
          const modifiedAdAccountList = [...this.state.modifiedAdAccountList]

          network.callApi({
            url: 'creative/delete',
            method: 'POST',
            body: {
              id: adAccountList[idx].id,
            },
            successFunc: (jsonData) => {
              if (jsonData.result) {
                const index = modifiedAdAccountList.findIndex((adAccount) => adAccount.id === adAccountList[idx].id)
                if (
                  modifiedAdAccountList[index].adCenterDiscountId &&
                  modifiedAdAccountList[index].adCenterDiscountId !== 0
                ) {
                  network.callApi({
                    url: 'discount/update',
                    method: 'POST',
                    body: { id: modifiedAdAccountList[index].adCenterDiscountId, isUsed: 1 },
                    successFunc: () => {
                      console.info(`success- creative/delete`)
                    },
                    failFunc: () => {
                      console.error(`fail- creative/delete`)
                    },
                  })
                }

                const { packagedId: modifiedPackagedId } = modifiedAdAccountList[index]
                modifiedAdAccountList.splice(index, 1)
                if (modifiedPackagedId) {
                  const i = modifiedAdAccountList.findIndex(
                    (adAccount) => adAccount.adCenterPubAreaId === modifiedPackagedId
                  )
                  if (i > -1) {
                    modifiedAdAccountList.splice(i, 1)
                  }
                }

                const { packagedId } = adAccountList[idx]
                adAccountList.splice(idx, 1)
                if (packagedId) {
                  const i = adAccountList.findIndex((adAccount) => adAccount.adCenterPubAreaId === packagedId)
                  if (i > -1) {
                    adAccountList.splice(i, 1)
                  }
                }

                let adsPrice = 0
                let adsVat = 0
                let totalPrice = 0

                adAccountList.forEach((adAccount) => {
                  if ((adAccount.status === 1 && adAccount.adProcessStatus !== 3) || adAccount.status === 4) {
                    if (adAccount.discountPrice || adAccount.discountPrice === 0) {
                      adsPrice += adAccount.discountPrice
                      adsVat += Math.floor(adAccount.discountPrice * 0.1)
                    } else {
                      adsPrice += adAccount.price
                      adsVat += Math.floor(adAccount.price * 0.1)
                    }
                  }
                })

                totalPrice = adsPrice + adsVat
                this.setState({
                  adAccountList,
                  adsPrice,
                  adsVat,
                  totalPrice,
                  modifiedAdAccountList,
                })
              }
            },
            failFunc: () => {
              console.error(`fail- creative/delete`)
            },
          })
        },
        // confirmData: { adAccountId },
      },
    })
  }

  adAccountListDelModalConfirm = (idx) => {
    const adAccountList = [...this.state.adAccountList]
    const modifiedAdAccountList = [...this.state.modifiedAdAccountList]

    network.callApi({
      url: 'creative/delete',
      method: 'POST',
      body: {
        id: this.state.modifiedAdAccountList[idx].id,
      },
      successFunc: (jsonData) => {
        if (jsonData.result) {
          const index = adAccountList.findIndex((adAccount) => adAccount.id === modifiedAdAccountList[idx].id)
          const selectedAreaId = modifiedAdAccountList[idx].packagedId || modifiedAdAccountList[idx].adCenterPubAreaId
          if (adAccountList[index].adCenterDiscountId) {
            network.callApi({
              url: 'discount/update',
              method: 'POST',
              body: { id: adAccountList[index].adCenterDiscountId, isUsed: 1 },
              successFunc: () => {
                console.info(`success- creative/delete`)
              },
              failFunc: () => {
                console.error(`fail- creative/delete`)
              },
            })
          }

          const removeFilter = (a) => a.packagedId !== selectedAreaId && a.adCenterPubAreaId !== selectedAreaId

          const filteredAccountList = adAccountList.filter(removeFilter)
          const filteredModifiedAccountList = modifiedAdAccountList.filter(removeFilter)
          // adAccountList.splice(index, 1);
          // modifiedAdAccountList.splice(idx, 1);

          let adsPrice = 0
          let adsVat = 0
          let totalPrice = 0

          filteredAccountList.forEach((adAccount) => {
            if (adAccount.discountPrice || adAccount.discountPrice === 0) {
              adsPrice += adAccount.discountPrice
              adsVat += Math.floor(adAccount.discountPrice * 0.1)
            } else {
              adsPrice += adAccount.price
              adsVat += Math.floor(adAccount.price * 0.1)
            }
          })

          totalPrice = adsPrice + adsVat

          this.setState({
            adAccountList: filteredAccountList,
            adsPrice,
            adsVat,
            totalPrice,
            modifiedAdAccountList: filteredModifiedAccountList,
          })
        }
      },
      failFunc: () => {
        console.error(`fail- creative/delete`)
      },
    })
  }

  handleAdAccountListDelModalOpen = (idx) => {
    this.props.openPopup({
      dialogType: 'adAccountConfirmDialog',
      dialogData: {
        type: 'delete',
        title: '만들고 있던 광고 상품을 삭제 하시겠습니까?',
        confirmText: '삭제',
        // confirmFunc: this.deleteAdAccount,
        confirmFunc: () => {
          this.adAccountListDelModalConfirm(idx)
        },
        // confirmData: { adAccountId },
      },
    })
  }

  onSelectGoods = (val, idx) => {
    const adAccountList = [...this.state.adAccountList]

    if (!val) {
      adAccountList[idx].impNum = 0
      adAccountList[idx].goodsid = 0
      adAccountList[idx].adCenterPubAccountId = 0
      adAccountList[idx].impPeriod = 0
      adAccountList[idx].price = 0
      adAccountList[idx].vat = 0

      delete adAccountList[idx].info
      delete adAccountList[idx].startAt
      delete adAccountList[idx].endAt

      this.setState({
        adAccountList,
      })
      return
    }

    const { impNum, adCenterPubAccountId, impPeriod, price } = adAccountList[idx].goodsList[val]

    adAccountList[idx].selectedGoods = adAccountList[idx].goodsList[val]
    // 패키지라면 서브 패키지 상품도 수정
    if (adAccountList[idx].packagedId) {
      const pId = adAccountList[idx].packagedId
      const otherIdx = idx === 0 ? 1 : 0
      if (pId === adAccountList[otherIdx].adCenterPubAreaId) {
        adAccountList[otherIdx].selectedGoods = adAccountList[otherIdx].goodsList[val]
      }
    }

    adAccountList[idx].impNum = impNum
    adAccountList[idx].adCenterPubAccountId = adCenterPubAccountId
    // adAccountList[idx].adCenterPubGoodsId = id
    adAccountList.forEach((v, index) => (v.adCenterPubGoodsId = adAccountList[index].goodsList[val].id))
    adAccountList[idx].price = price
    adAccountList[idx].impPeriod = impPeriod
    adAccountList[idx].vat = Math.floor(price * 0.1)
    adAccountList[idx].nameErrMsg = ''
    adAccountList[idx].goodsIdx = val
    adAccountList[idx].costPrice = adAccountList[idx].selectedGoods.price

    adAccountList[idx].endAt = dayjs(adAccountList[idx].startAt)
      .add(impPeriod - 1, 'day')
      .format('YYYY-MM-DD')

    if (adAccountList[idx].discountCoupon.length > 0) {
      if (adAccountList[idx].usedDiscount === 2) {
        if (adAccountList[idx].discountIdx >= 0) {
          adAccountList[idx].discountPrice =
            adAccountList[idx].price -
            (adAccountList[idx].price *
              adAccountList[idx].discountCoupon[adAccountList[idx].discountIdx].discountRate) /
              100
          adAccountList[idx].vat = Math.floor(adAccountList[idx].discountPrice * 0.1)
        }
      } else {
        adAccountList[idx].discountIdx = ''
        delete adAccountList[idx].discountPrice
      }
      adAccountList[idx].price = adAccountList[idx].selectedGoods.price
    } else {
      adAccountList[idx].discountPrice = null
    }

    let adsPrice = 0
    let adsVat = 0
    let totalPrice = 0

    adAccountList.forEach((adAccount) => {
      if ((adAccount.status === 1 && adAccount.adProcessStatus !== 3) || adAccount.status === 4) {
        if (adAccount.discountPrice || adAccount.discountPrice === 0) {
          adsPrice += adAccount.discountPrice
          adsVat += Math.floor(adAccount.discountPrice * 0.1)
        } else {
          adsPrice += adAccount.price
          adsVat += Math.floor(adAccount.price * 0.1)
        }
      }
    })

    totalPrice = adsPrice + adsVat

    // adAccountList[idx].impNum = adAccountList[idx].goodsList[val].impNum;
    // adAccountList[idx].goodsid = adAccountList[idx].goodsList[val].id;
    // adAccountList[idx].impPeriod = adAccountList[idx].goodsList[val].impPeriod;

    this.setState({
      adAccountList,
      adsPrice,
      adsVat,
      totalPrice,
    })
  }

  onSelectDiscountCoupon = (val, idx) => {
    const { adAccountList } = this.state
    const newAccountList = [...adAccountList]

    const account = newAccountList[idx]

    if (val === '' || !val) {
      account.adCenterDiscountId = ''
      account.discountIdx = ''
      account.discountPrice = account.price
    } else {
      account.discountIdx = val
      account.adCenterDiscountId = account.discountCoupon[val].id
      account.discountPrice = account.price - (account.price * account.discountCoupon[val].discountRate) / 100
    }

    let adsPrice = 0
    let adsVat = 0
    let totalPrice = 0

    adAccountList.forEach((adAccount) => {
      if ((adAccount.status === 1 && adAccount.adProcessStatus !== 3) || adAccount.status === 4) {
        if (adAccount.discountPrice || adAccount.discountPrice === 0) {
          adsPrice += adAccount.discountPrice
          adsVat += Math.floor(adAccount.discountPrice * 0.1)
        } else {
          adsPrice += adAccount.price
          adsVat += Math.floor(adAccount.price * 0.1)
        }
      }
    })

    totalPrice = adsPrice + adsVat

    this.setState({
      adAccountList: newAccountList,
      adsPrice,
      adsVat,
      totalPrice,
    })
  }

  onChangeDateRange = (startAt, endAt, idx, checkHolidays) => {
    const adAccountList = [...this.state.adAccountList]
    const newAccountList = [...adAccountList]
    const currentAccount = newAccountList[idx]
    currentAccount.startAt = startAt
    currentAccount.endAt = endAt

    currentAccount.holidayPeriod = checkHolidays.holidays
    currentAccount.normalPeriod = checkHolidays.normalDays

    const price =
      currentAccount.selectedGoods.price * checkHolidays.normalDays +
      currentAccount.selectedGoods.holidayPrice * checkHolidays.holidays
    currentAccount.vat = Math.floor(price * 0.1)

    const adsPrice = price
    const adsVat = Math.floor(price * 0.1)
    const totalPrice = price + currentAccount.vat

    if (currentAccount.packagedId) {
      const pIdx = newAccountList.findIndex((acc) => acc.adCenterPubAreaId === currentAccount.packagedId)
      if (pIdx > -1) {
        const slaveAccount = newAccountList[pIdx]
        slaveAccount.startAt = currentAccount.startAt
        slaveAccount.endAt = currentAccount.endAt
      }
    }

    this.setState({
      adAccountList: newAccountList,
      totalPrice,
      adsPrice,
      adsVat,
    })
  }

  onFocusChange = (name, value, idx) => {
    const adAccountList = [...this.state.adAccountList]
    const newAccountList = [...adAccountList]

    if (name.indexOf('adStartDateFocus_') >= 0) {
      newAccountList[idx].adStartDateFocus = value
    } else {
      newAccountList[idx].adEndDateFocus = value
    }

    this.setState({
      adAccountList: newAccountList,
    })
  }

  handleClickDatepicker = (data, idx) => {
    this.props.openPopup({
      dialogType: 'adDate',
      dialogData: {
        data,
        openDate: this.state.openDate,
        confirmFunc: (startDate, endDate, startTime) => this.handleSetAdDate(idx, startDate, endDate, startTime),
      },
    })
  }

  handleSetAdDate = (idx, startDate, endDate, startTime) => {
    const adAccountList = [...this.state.adAccountList]

    const splitedStartAt = startDate.split('-')
    const splitedEndAt = endDate.split('-')

    const sendDateFlag = adAccountList[idx].startAt
      ? adAccountList[idx].startAt.substring(0, 10) !==
        `${splitedStartAt[0]}-${splitedStartAt[1][1] ? splitedStartAt[1] : `0${splitedStartAt[1]}`}-${
          splitedStartAt[2][1] ? splitedStartAt[2] : `0${splitedStartAt[2]}`
        }`
      : true

    adAccountList[idx].startAt = `${splitedStartAt[0]}-${
      splitedStartAt[1][1] ? splitedStartAt[1] : `0${splitedStartAt[1]}`
    }-${splitedStartAt[2][1] ? splitedStartAt[2] : `0${splitedStartAt[2]}`}`
    adAccountList[idx].endAt = `${splitedEndAt[0]}-${splitedEndAt[1][1] ? splitedEndAt[1] : `0${splitedEndAt[1]}`}-${
      splitedEndAt[2][1] ? splitedEndAt[2] : `0${splitedEndAt[2]}`
    }`
    adAccountList[idx].startTime = startTime
    adAccountList[idx].sendDateFlag = sendDateFlag
    adAccountList[idx].dateErrMsg = ''
    this.setState({ adAccountList, startTime })
  }

  handlePreviewModalOpen = (title, imgUrl, mainCopy, subCopy, bgColor, pubAreaId) => {
    this.props.openPopup({
      dialogType: 'previewDialog',
      dialogData: {
        title,
        confirmText: '닫기',
        imgUrl,
        mainCopy,
        subCopy,
        bgColor,
        pubAreaId,
      },
    })
  }

  imgFileSel = (e, idx, iwidth, iheight, isize) => {
    const { target } = e
    const tempfile = target.files[0]
    const fr = new FileReader()
    const image = new Image()
    if (!tempfile) return
    fr.readAsDataURL(tempfile)
    fr.onload = (file) => {
      image.src = file.target.result
      image.onload = () => {
        const { height, width } = image
        if (width !== iwidth || height !== iheight) {
          toast('이미지 사이즈를 확인하세요.')
          target.value = null
          return
        }
        if (tempfile.size > isize * 1024 * 1024) {
          toast('이미지 용량을 확인하세요.')
          target.value = null
          return
        }

        const data = new FormData()
        data.append('img_file', tempfile)

        const { adAccountList } = this.state
        const { adCenterPubAreaId } = adAccountList[idx]
        const { goodsIdx, goodsList, selectedGoods } = adAccountList[0] //패키지에 경우 메인이 항상 0에 오는 것으로 가정한다.
        const { badgeType } = goodsList[goodsIdx] || selectedGoods
        data.append(
          'img_type',
          badgeType === 'Popularity' && adCenterPubAreaId === AreaType.comingSoonList ? 'COMBINATION' : 'NORMAL'
        )

        network.callApi({
          url: 'creative/image/upload',
          method: 'post',
          body: data,
          isFormData: true,
          successFunc: ({ result, response }) => {
            if (!result) {
              toast('이미지 업로드 실패')
              return
            }
            this.setImage(response.imgUrl, idx)
          },
          failFunc: () => {
            console.error(`fail- creative/image/upload`)
          },
        })
      }
    }
  }

  setImage = (imgUrl, idx) => {
    const { adAccountList } = this.state
    const newAccountList = [...adAccountList]
    newAccountList[idx].imgUrl = imgUrl
    newAccountList[idx].imageErrMsg = ''
    this.setState({ adAccountList: newAccountList }, () => {
      const imgElm = document.getElementById(`fileImg${idx}`)
      if (imgElm) {
        imgElm.src = imgUrl
      }
    })
  }

  imgDel = (idx) => {
    const { adAccountList } = this.state
    const newAccountList = [...adAccountList]
    delete newAccountList[idx].imgUrl

    this.setState({ adAccountList: newAccountList })
  }

  selectColor = (selColor, idx) => {
    const { adAccountList } = this.state
    const newAccountList = [...adAccountList]

    newAccountList[idx].bgColor = selColor.bgColor
    newAccountList[idx].adCenterBgColorId = selColor.id
    newAccountList[idx].backgroundColorErrMsg = ''
    this.setState({ adAccountList: newAccountList })
  }

  handleChangeMainCopy = (value, idx) => {
    const adAccountList = [...this.state.adAccountList]
    adAccountList[idx].mainCopy = value
    adAccountList[idx].mainCopyErrMsg = ''
    this.setState({ adAccountList })
  }

  handleChangeKeyword = (value, idx) => {
    const adAccountList = [...this.state.adAccountList]
    adAccountList[idx].keyword = value
    this.setState({ adAccountList })
  }
  handleChangeSubCopy = (value, idx) => {
    const adAccountList = [...this.state.adAccountList]
    adAccountList[idx].subCopy = value
    adAccountList[idx].subCopyErrMsg = ''
    this.setState({ adAccountList })
  }

  handleChangeLandingUrl = (event, idx) => {
    const adAccountList = [...this.state.adAccountList]
    adAccountList[idx].landingUrl = event.target.value
    this.setState({ adAccountList })
  }

  handleClickPrevButton = (step) => {
    if (step === 0) this.handleClickGoToAdList()
    else
      this.props.history.push({
        pathname: `/main/ad/modify2/${this.state.projectId}/step${step}`,
        state: { ...this.state },
      })
    window.scrollTo(0, 0)
  }

  validateFields = () => {
    if (this.state.projectId === 0) {
      this.setState({ selectProjectErrMsg: '프로젝트를 선택하세요.' })
      toast('입력 정보를 확인하세요.')
      return false
    }

    if (this.state.adName === '') {
      this.setState({ changeAdNameErrMsg: '광고명을 입력하세요.' })
      toast('입력 정보를 확인하세요.')
      return false
    }

    let flag = false
    let toastMsg = ''
    const adAccountList = this.state.adAccountList.map((val) => {
      val.nameErrMsg =
        !Object.prototype.hasOwnProperty.call(val, 'id') || val.selectedGoods.id === '' ? '광고 상품을 선택하세요.' : ''
      val.dateErrMsg =
        !Object.prototype.hasOwnProperty.call(val, 'startAt') || val.startAt === '' ? '광고 기간을 선택하세요.' : ''
      val.imageErrMsg =
        (!Object.prototype.hasOwnProperty.call(val, 'imgUrl') || val.imgUrl === '') && !(val.info.imageYn === 0)
          ? '광고 소재를 등록해주세요.'
          : ''
      val.backgroundColorErrMsg =
        (!Object.prototype.hasOwnProperty.call(val, 'bgColor') || val.bgColor === '') && !(val.info.bgColorYn === 0)
          ? '색상을 선택하세요.'
          : ''
      val.mainCopyErrMsg =
        (!Object.prototype.hasOwnProperty.call(val, 'mainCopy') || val.mainCopy === '') && !(val.info.mainCopyYn === 0)
          ? '메인 카피를 등록하세요.'
          : ''
      val.subCopyErrMsg =
        (!Object.prototype.hasOwnProperty.call(val, 'subCopy') || val.subCopy === '') && !(val.info.subCopyYn === 0)
          ? '서브 카피를 등록하세요.'
          : ''

      if (
        (val.info.mainCopyYn === 1 && val.info.mainCopyLineNum <= 1 && val.mainCopy.indexOf('\n') >= 0) ||
        (val.info.subCopyYn === 1 && val.info.subCopyLineNum <= 1 && val.subCopy.indexOf('\n') >= 0)
      ) {
        toastMsg = '줄바꿈 여부를 확인하세요.'
      }
      if (val.info.adCenterPubAreaId === AreaType.searchKeyword && !val.keyword) {
        flag = true
      }

      flag =
        flag ||
        toastMsg !== '' ||
        val.nameErrMsg !== '' ||
        val.dateErrMsg !== '' ||
        val.imageErrMsg !== '' ||
        val.backgroundColorErrMsg !== '' ||
        val.mainCopyErrMsg !== '' ||
        val.subCopyErrMsg !== ''
      return val
    })

    if (flag) {
      this.setState({ adAccountList })
      toast(toastMsg !== '' ? toastMsg : '입력 정보를 확인하세요.')
    }
    return !flag
  }

  doSaveModified = (completeState) => {
    if (!this.validateFields()) {
      return
    }
    const { adId, campaignId, projectType, usingCouponList } = this.state
    const { isWadizAdmin } = this.props.user
    let adAccountList = [...this.state.adAccountList]
    const tmpCouponList = []
    const promiseList = []

    this.setState({ loadingBarFlag: true }, () => {
      const updateAd = new Promise((resolve, reject) => {
        return network.callApi({
          url: 'advertisement/update',
          method: 'POST',
          body: {
            adCenterProjectId: this.state.projectId,
            adName: this.state.adName,
            adPrice: this.state.totalPrice,
            id: this.state.adId,
          },
          successFunc: (jsonData) => {
            resolve(jsonData)
          },
          failFunc: (error) => {
            reject(error)
          },
        })
      })
      promiseList.push(updateAd)

      const updateRequestBodyList =
        completeState !== 'success' && completeState !== 'exist'
          ? []
          : adAccountList
              .filter((adAccount) => !!adAccount.callApiType)
              .map((adAccount) => {
                const {
                  id,
                  adCenterBgColorId,
                  adCenterPubAccountId,
                  adCenterPubAreaId,
                  selectedGoods: { id: adCenterPubGoodsId },
                  bgColor,
                  mainCopy,
                  keyword,
                  subCopy,
                  landingUrl,
                  imgUrl,
                  discountPrice,
                  price: oldPrice,
                  adCenterDiscountId,
                  adState,
                } = adAccount
                const isDiscount = adCenterDiscountId && adCenterDiscountId !== 0 && adCenterDiscountId !== 1
                const price = discountPrice || discountPrice === 0 ? discountPrice : oldPrice
                const reservedPayDate = dayjs().format('YYYY-MM-DD HH:mm:ss')
                return {
                  id,
                  adCenterBgColorId,
                  adCenterPubAccountId,
                  adCenterPubAreaId,
                  adCenterAdvertisementId: adId,
                  adCenterPubGoodsId,
                  adCenterStatusId: 1,
                  bgColor,
                  mainCopy,
                  keyword,
                  subCopy,
                  landingUrl,
                  advertiserDivision: isWadizAdmin ? 1 : 0,
                  imgUrl: Object.prototype.hasOwnProperty.call(adAccount, 'imgUrl') ? imgUrl : null,
                  price: discountPrice || discountPrice === 0 ? discountPrice : oldPrice,
                  adCenterDiscountId: isDiscount ? adCenterDiscountId : 1,
                  usedDiscount: isDiscount ? 2 : 1,
                  costPrice: oldPrice,
                  vat: Math.floor(price * 0.1),
                  status: 2,
                  adProcessStatus: adState === 'Payment_Failed' ? 7 : 6,
                  cancelStatus: 5,
                  requestDate: reservedPayDate,
                  reservedPayDate,
                }
              })
      if (updateRequestBodyList.length > 0) {
        const updateAdAccount = new Promise((resolve, reject) => {
          const url = this.state.shared === 1 ? 'creative/update-sync' : 'creative/update'

          return network.callApi({
            url,
            method: 'POST',
            body: this.state.shared === 1 ? updateRequestBodyList[0] : updateRequestBodyList,
            successFunc: (jsonData) => {
              if (jsonData.result !== 'success') {
                toast(jsonData.message)
              }
              if (completeState === 'exist') {
                this.props.openAlertPopup({
                  alertData: {
                    title: '결제 예약이 완료되었습니다.',
                    content: <p>간편 결제로 등록 요청하신 카드는 이미 간편 결제에 등록되어, 추가 등록되지 않습니다.</p>,
                  },
                })
              }
              resolve(jsonData)
            },
            failFunc: (error) => {
              reject(error)
            },
          })
        })
        promiseList.push(updateAdAccount)
      }

      if (usingCouponList.length > 0) {
        usingCouponList.forEach((coupon) => {
          promiseList.push(
            new Promise((resolve, reject) => {
              return network.callApi({
                url: 'discount/update',
                method: 'POST',
                body: { id: coupon, isUsed: 1 },
                successFunc: (discountCouponResult) => {
                  resolve(discountCouponResult)
                },
                failFunc: (error) => {
                  reject(error)
                },
              })
            })
          )
        })
      }
      if (tmpCouponList.length > 0) {
        tmpCouponList.forEach((coupon) => {
          promiseList.push(
            new Promise((resolve, reject) => {
              return network.callApi({
                url: 'discount/update',
                method: 'POST',
                body: { id: coupon, isUsed: 2 },
                successFunc: (discountCouponResult) => {
                  resolve(discountCouponResult)
                },
                failFunc: (error) => {
                  reject(error)
                },
              })
            })
          )
        })
      }

      const { adId: stateAdId } = this.state
      Promise.all(promiseList).then(() => {
        if (isWadizAdmin) {
          this.handleClickGoToAdList()
          toast('광고가 수정되었습니다.')
          return
        }
        network.callApi({
          url: `creative/list?advertisement_id=${stateAdId}`,
          method: 'get',
          successFunc: (jsonData) => {
            adAccountList = []
            adAccountList = jsonData.result

            this.setState({
              shared: jsonData.result[0].shared,
            })

            const packagedIdList = adAccountList.reduce(
              (a, item) => [...a, ...(!item.packagedId ? [] : [item.packagedId])],
              []
            )
            packagedIdList.forEach((packagedId) => {
              const slavePackage = adAccountList.find((n) => n.adCenterPubAreaId === packagedId)
              if (!slavePackage) {
                return
              }
              slavePackage.isSlavePackage = true
            })

            const newPromiseList = []
            adAccountList.forEach((adAccount) => {
              const tempAdAccount = adAccount
              let selectType = tempAdAccount.packagedId ? 2 : 0

              // 슈퍼유저일 경우 다중지면도 패키지가 아닌 단일로 해야함 type: 0 - 일반 / 2 - 패키지
              if (this.props.user.isSuperUser) selectType = 0

              newPromiseList.push(
                new Promise((resolve, reject) => {
                  return network.callApi({
                    url: `goods/list?pub_account_id=${tempAdAccount.adCenterPubAccountId}&advertiser_type=${
                      isWadizAdmin ? 1 : 0
                    }&type=${selectType}&${
                      projectType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'
                    }=${campaignId}`,
                    method: 'get',
                    successFunc: (goodsListData) => {
                      tempAdAccount.goodsList = goodsListData.result
                      for (let j = 0; j < goodsListData.result.length; j += 1) {
                        if (goodsListData.result[j].id === tempAdAccount.adCenterPubGoodsId) {
                          tempAdAccount.selectedGoods = goodsListData.result[j]
                          break
                        }
                      }
                      resolve(jsonData.result)
                    },
                    failFunc: (error) => {
                      reject(error)
                    },
                  })
                })
              )
              newPromiseList.push(
                new Promise((resolve, reject) => {
                  return network.callApi({
                    url: `pub-account/info?pub_account_id=${tempAdAccount.adCenterPubAccountId}`,
                    method: 'get',
                    successFunc: (accountInfoData) => {
                      tempAdAccount.info = accountInfoData.result
                      resolve(accountInfoData.result)
                    },
                    failFunc: (error) => {
                      reject(error)
                    },
                  })
                })
              )
              newPromiseList.push(
                new Promise((resolve, reject) => {
                  const url =
                    tempAdAccount.adCenterDiscountId === 0
                      ? `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${adAccount.adCenterPubAccountId}&is_used=1`
                      : `discount/list?campaign_id=${campaignId}&project_type=${projectType}&pub_account_id=${adAccount.adCenterPubAccountId}`
                  return network.callApi({
                    url,
                    method: 'get',
                    successFunc: (discountCouponData) => {
                      if (discountCouponData.result.list && discountCouponData.result.list.length > 0) {
                        const discountCoupon = [...discountCouponData.result.list]
                        if (tempAdAccount.adCenterDiscountId !== 0) {
                          for (let i = 0; i < discountCoupon.length; i += 1) {
                            if (
                              discountCoupon[i].isUsed === 2 &&
                              tempAdAccount.adCenterDiscountId &&
                              tempAdAccount.adCenterDiscountId !== discountCoupon[i].id
                            ) {
                              discountCoupon.splice(i, 1)
                            }
                          }
                        }
                        tempAdAccount.discountCoupon = discountCoupon
                      } else {
                        tempAdAccount.discountCoupon = []
                      }
                      resolve()
                    },
                    failFunc: (error) => {
                      reject(error)
                    },
                  })
                })
              )
            })
            Promise.all(newPromiseList).then(() => {
              adAccountList.forEach((adAccount) => {
                const tempAdAccount = adAccount
                tempAdAccount.positionName = tempAdAccount.area
                tempAdAccount.impPeriod = tempAdAccount.selectedGoods.impPeriod
                tempAdAccount.adCenterPubAreaId = tempAdAccount.info.adCenterPubAreaId
                tempAdAccount.impNum = tempAdAccount.selectedGoods.impNum
                tempAdAccount.pricingType = tempAdAccount.info.pricingType
                tempAdAccount.goodsIdx = tempAdAccount.goodsList?.findIndex(
                  (goods) => goods.id === tempAdAccount.selectedGoods.id
                )
                if (tempAdAccount.discountCoupon.length > 0) {
                  tempAdAccount.discountIdx = tempAdAccount.discountCoupon.findIndex(
                    (coupon) => tempAdAccount.adCenterDiscountId === coupon.id
                  )
                  tempAdAccount.discountIdx = tempAdAccount.discountIdx < 0 ? '' : tempAdAccount.discountIdx
                  tempAdAccount.discountPrice = tempAdAccount.price
                  tempAdAccount.price = tempAdAccount.selectedGoods.price
                  tempAdAccount.vat = Math.floor(tempAdAccount.discountPrice * 0.1)
                } else {
                  tempAdAccount.discountPrice = null
                  tempAdAccount.vat = Math.floor(tempAdAccount.price * 0.1)
                }
              })
              const tempAdAccountList = []
              const tempIndexArray = []
              for (let i = 0; i < adAccountList.length; i += 1) {
                if (adAccountList[i].status === 1) {
                  tempAdAccountList.push(adAccountList[i])
                  tempIndexArray.push(i)
                }
              }
              for (let i = 0; i < adAccountList.length; i += 1) {
                if (tempIndexArray.indexOf(i) < 0) {
                  tempAdAccountList.push(adAccountList[i])
                }
              }

              adAccountList = tempAdAccountList
              this.setState(
                {
                  adAccountList,
                  modifiedAdAccountList: tempAdAccountList,
                  selectProjectErrMsg: '',
                  changeAdNameErrMsg: '',
                  loadingBarFlag: false,
                  usingCouponList: tmpCouponList,
                },
                () => {
                  this.props.history.push({
                    pathname: `/main/ad/modify2/${this.state.projectId}/step2`,
                    state: { ...this.state },
                  })
                  // this.setState({ isComplete }, () => window.scrollTo(0, 0));
                }
              )
            })
          },
          failFunc: () => {
            console.error(`fail- promise all`)
          },
        })
      })
    })

    window.scrollTo(0, 0)
  }

  validateAccountListOfPackage = (aList) => {
    const packageIdList = aList.reduce((idList, n) => (!n.packagedId ? idList : [...idList, n.packagedId]), [])
    const subPackageList = packageIdList
      .map((n) => aList.find((acc) => acc.adCenterPubAreaId === n))
      .filter((item) => item !== undefined)
    const isNotValidate =
      subPackageList.reduce((failureList, sub) => {
        if (sub.costPrice !== 0) {
          return [...failureList, sub]
        }
        return failureList
      }, []).length > 0

    return !isNotValidate
  }

  estimationPubAreaAvailability = (list) => {
    // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
    if (list.findIndex((r) => r?.data?.availability === 'Closed') > -1) {
      return 'Closed'
    }
    if (list.findIndex((r) => r?.data?.availability === 'Imminent') > -1) {
      return 'Imminent'
    }
    if (list.findIndex((r) => r?.data?.availability === 'Unavailable') > -1) {
      return 'Unavailable'
    }

    return 'Available'
  }

  doAgreementConfirm = (isConfirmed) => {
    this.setState({ isOpenAgreementModal: false }, () => {
      if (isConfirmed) {
        this.handleOpenReservationPaymentDialogImp()
      }
    })
  }

  isFakeWadizOnlyCondition = async () => {
    if (!this.isWadizOnlyGoodsSelected() || !this.isStoreProject() || this.props.user.isSuperUser) {
      return false
    }
    const wadizOnlyCondition = getWadizOnlyCondition({
      adMakerId: this.props.user?.maker_id,
      wdzStoreProjectId: this.state.campaignId,
    })
    return !wadizOnlyCondition
  }

  handleOpenReservationPaymentDialog = async () => {
    const isFakeWadizOnly = await this.isFakeWadizOnlyCondition()

    if (isFakeWadizOnly) {
      this.props.openWaffleModal({
        title: '광고 상품을 다시 선택해 주세요',
        message: '해당 프로젝트는 와디즈 Only 프로젝트가 아니므로 할인 적용 대상이 아닙니다.',
        buttonColor: 'secondary',
      })
      return
    }

    const isAgreements = getTermsAgreements({ adMakerId: this.props.user?.maker_id })
    if (isAgreements) {
      this.handleOpenReservationPaymentDialogImp()
    } else {
      this.setState({ isOpenAgreementModal: true })
    }
  }

  handleOpenReservationPaymentDialogImp = () => {
    if (!this.validateAccountListOfPackage(this.state.modifiedAdAccountList)) {
      toast('패키지 설정을 확인해 주세요. ')
      return
    }

    const { projectType, campaignId } = this.state
    const accountList =
      this.state.modifiedAdAccountList.length > 0 ? this.state.modifiedAdAccountList : this.state.adAccountList
    const isPreAvailable = accountList.reduce(
      (someAvailable, account) =>
        someAvailable ||
        account.adState === 'Payment_Failed' ||
        account.adState === 'Accepted' ||
        account.adState === 'Feedback_in_Contr',
      false
    )
    ;(isPreAvailable
      ? Promise.resolve([{ data: { availability: 'Available' } }])
      : Promise.all(
          accountList.map((account) =>
            centerGetPubAreaAvailability({
              startAt: dayjs(account.startAt).format('YYYY-MM-DD'),
              endAt: dayjs(account.endAt).format('YYYY-MM-DD'),
              pubAreaId: account.adCenterPubAreaId,
              projectType,
              campaignId,
            })
          )
        )
    ).then((res) => {
      // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
      const availability = this.estimationPubAreaAvailability(res)
      if (availability === 'Unavailable') {
        this.props.openWaffleModal({
          title: '다른 기간을 선택해 주세요.',
          message: '같은 기간에 이미 진행중인 광고가 있습니다',
        })
        return
      }

      this.props.openPopup({
        dialogType: 'creditCardDetailDialog2',
        dialogData: {
          title: '카드 결제 정보',
          price: this.state.totalPrice,
          advertisementId: this.state.adId,
          accountList,
          confirmFunc: (completeState) => this.doSaveModified(completeState),
          closeText: '닫기',
          areaAvailable: availability,
        },
      })
    })
  }

  handleClickGoToAdList = () => {
    this.props.setMenuStatus(false)
    this.props.history.push('/main/ad/list')
  }

  comma = (val) => {
    if (typeof val === 'string') return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (typeof val === 'number') return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return val
  }

  scrollToAdAccountCardTop = () => {
    if (this.selectedAdAccountCardRef.current && this.selectedAdAccountCardRef.current.offsetParent) {
      window.scrollTo(0, this.selectedAdAccountCardRef.current.offsetParent.offsetTop)
      this.setState({ selectedAdAccountCardId: 0 })
    }
  }

  onChangeLandingURL = (isProjectURL) => {
    const { adAccountList, makerInfo, landingUrl } = this.state
    const newAccountList = adAccountList.map((account) => ({
      ...account,
      landingUrl: isProjectURL ? landingUrl : makerInfo.makerPageLandingUrl,
      makerPageUrlSelected: !isProjectURL,
    }))
    this.setState({
      adAccountList: newAccountList,
    })
  }

  render() {
    const {
      handleOpenGuideType,
      handleChangeProject,
      handleChangeAdName,
      handleAdAccountModalOpen,
      adAccountListDel,
      handleAdAccountListDelModalOpen,
      onSelectGoods,
      handleClickDatepicker,
      handlePreviewModalOpen,
      imgFileSel,
      imgDel,
      selectColor,
      handleChangeMainCopy,
      handleChangeKeyword,
      handleChangeSubCopy,
      handleChangeLandingUrl,
      handleClickPrevButton,
      handleOpenReservationPaymentDialog,
      handleClickGoToAdList,
      comma,
      selectedAdAccountCardRef,
      onSelectDiscountCoupon,
      onChangeDateRange,
      onFocusChange,
      doAgreementConfirm,
      onChangeLandingURL,
      setImage,
    } = this

    const sortedAdAccountList = sortByArea(this.state.adAccountList, this.props.user.isSuperUser)
    const { isOpenAgreementModal } = this.state

    return (
      <>
        <AdModifyContent
          state={this.state}
          adAccountList={sortedAdAccountList}
          isWadizAdmin={this.props.user.isWadizAdmin}
          handleOpenGuideType={handleOpenGuideType}
          handleChangeAdName={handleChangeAdName}
          handleChangeProject={handleChangeProject}
          handleAdAccountModalOpen={handleAdAccountModalOpen}
          adAccountListDel={adAccountListDel}
          handleAdAccountListDelModalOpen={handleAdAccountListDelModalOpen}
          onSelectGoods={onSelectGoods}
          onSelectDiscountCoupon={onSelectDiscountCoupon}
          handleClickDatepicker={handleClickDatepicker}
          handlePreviewModalOpen={handlePreviewModalOpen}
          imgFileSel={imgFileSel}
          imgDel={imgDel}
          selectColor={selectColor}
          handleChangeMainCopy={handleChangeMainCopy}
          handleChangeKeyword={handleChangeKeyword}
          handleChangeSubCopy={handleChangeSubCopy}
          handleChangeLandingUrl={handleChangeLandingUrl}
          handleClickPrevButton={handleClickPrevButton}
          handleOpenReservationPaymentDialog={handleOpenReservationPaymentDialog}
          handleClickGoToAdList={handleClickGoToAdList}
          comma={comma}
          onChangeDateRange={onChangeDateRange}
          onFocusChange={onFocusChange}
          selectedAdAccountCardRef={selectedAdAccountCardRef}
          onChangeLandingURL={onChangeLandingURL}
          setImage={setImage}
        />
        <TermsModalContainer isOpen={isOpenAgreementModal} onAgreementConfirm={doAgreementConfirm} />
      </>
    )
  }
}

export default connect(({ user, dialog }) => ({ user, dialog }), {
  openPopup,
  openAlertPopup,
  setMenuStatus,
  openWaffleModal,
})(withRouter(AdModifyPage))
