import axios, { Method, AxiosResponse, AxiosRequestConfig } from 'axios'
import Cookies from 'universal-cookie'

const makeHeader = () => ({
  'Content-Type': 'application/json;charset=UTF-8',
  token: new Cookies().get('token')
})
const baseURL = `${process.env.REACT_APP_WADIZ_API_HOST || import.meta.env.VITE_API_HOST}api/v1/ad/`

const instance =
  ({ timeout = 20000 }) =>
  <T>(method: Method, url: string, opt?: AxiosRequestConfig): Promise<T> =>
    axios({
      headers: makeHeader(),
      baseURL,
      timeout,
      ...(opt || {}),
      method,
      url,
    }).then(
      (res: AxiosResponse<T>) => {
        return res.data
      },
      (err) => {
        if (err?.response?.status === 401) {
          if (window.location.pathname.indexOf('login') < 0) {
            window.location.href = '/login'
          }
          return err as T
        }
        throw err
      }
    )
export const api = instance({})
export const apiLongTime = instance({ timeout: 5000000 })
