import React, { ReactElement } from 'react';
import ConfirmModal, { ButtonsDirection, ModalSize }  from '../ConfirmModal';
import { ButtonProps } from '../Button';
export interface InfoModalProps {
  title: string
  message?: string
  buttonLabel?: string | null
  onButtonClick?: () => void
  buttonProps?: ButtonProps<HTMLButtonElement | HTMLAnchorElement>
  buttonsDirection?: keyof typeof ButtonsDirection
  size?: keyof typeof ModalSize
  showCloseButton?: boolean
  onClose?: () => void
  overlayRef?: () => void
  autoClose?: boolean
  closeClickOutside?: boolean
  cleanUp?: () => void
  zIndex?: number
  children?: string | ReactElement
  preventBodyScroll?: boolean
  closeWithHistoryBack?: boolean
  fullHeight?: boolean
  headerClassName?: string
  contentClassName?: string
  footerClassName?: string
}

export const InfoModal = ({
    title,
    message,
    buttonLabel = '확인',
    onButtonClick,
    showCloseButton = true,
    onClose,
    overlayRef,
    buttonsDirection = 'horizontal',
    size = 'md',
    preventBodyScroll ,
    autoClose = true,
    zIndex,
    children,
    closeClickOutside,
    closeWithHistoryBack,
    cleanUp,
    buttonProps = {},
    fullHeight,
    headerClassName,
    contentClassName,
    footerClassName,
  }: InfoModalProps) => {
  return (
    <ConfirmModal
      title={title}
      message={message}
      negativeButtonLabel={null}
      positiveButtonLabel={buttonLabel}
      onPositiveButtonClick={onButtonClick}
      buttonsDirection={buttonsDirection}
      size={size}
      positiveButtonProps={buttonProps}
      showCloseButton={showCloseButton}
      zIndex={zIndex}
      autoClose= {autoClose}
      onClose={onClose}
      overlayRef={overlayRef}
      preventBodyScroll={preventBodyScroll}
      closeClickOutside={closeClickOutside}
      closeWithHistoryBack={closeWithHistoryBack}
      cleanUp={cleanUp}
      fullHeight={fullHeight}
      headerClassName={headerClassName}
      contentClassName={contentClassName}
      footerClassName={footerClassName}
    >
      {children}
    </ConfirmModal>
  );
};

export default InfoModal;
