import React from 'react'

import { Typography } from 'antd';
import { numberFormat } from '@wadiz/utils';
import className from 'classnames/bind'
import style from './ModifyAdStep2.module.scss'
import { AdStaticPreview } from '@common/ui'
import { AreaType } from '@common'
import PreviewContainer from '../../../containers/PreviewContainer/PreviewContainer'
import dayjs from 'dayjs';

const cx = className.bind(style)
const { Text } = Typography;

const comma = (val) => {
  if (typeof val === 'string') return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (typeof val === 'number') return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return val
}

const ModifyAdStep2 = ({
  handleAdAccountListDelModalOpen,
  handleOpenReservationPaymentDialog,
  handleClickPrevButton,
  handleClickGoToAdList,
  ...rest
}) => {
  const { isComplete, modifiedAdAccountList, projectType, lossText, totalPrice, holidays: holidayList, adAccountList } = rest

  const isCreatedState = modifiedAdAccountList.length < 1 ? false : modifiedAdAccountList[0].adState === 'Creating'

  const countHolidays = (startDate, goods) => {
    if (!holidayList) {
      return {
        normalDays: 0,
        holidays: 0,
      }
    }
    // 일반 = o, 공휴일 = h, 대체공휴일 = i, 임시공휴일 = t
    const list = holidayList.filter(item => item.type !== 'o');
    let normalDays = 0, holidays = 0;

    // 휴일, 평일 체크
    const dateRange = new Array(goods.impMinPeriod).fill('').map((_, idx) => dayjs(startDate).add(idx, 'day').format('YYYY-MM-DD'));
    dateRange.forEach(date => {
      if (list.some(i => date === i.eventDate)) {
        holidays += 1;
      } else {
        normalDays += 1;
      }
    });
    return {
      normalDays,
      holidays,
    };
  }

  const HolidayInPrice = ({ idx, val }) => {
    let normalDays = 0, holidays = 0;
    const account = adAccountList[idx]
    const goods = account.goodsList[account.goodsIdx];

    if (!account?.holidays) {
      const data = countHolidays(account.startAt, goods);
      normalDays = data.normalDays;
      holidays = data.holidays;
    } else {
      normalDays = account.normalDays;
      holidays = account.holidays;
    }

    return (
      <Text style={{ textAlign: 'right', display: 'block', width: '100%', color: '#90949c' }}>
        평일: {normalDays}일 - {numberFormat(goods.price * normalDays) || 0}원 /
        휴일: {holidays}일 - {numberFormat(goods.holidayPrice * holidays) || 0}원
      </Text>
    )
  };

  return (
    // <div className={cx('modify_ad')}>
    <div className={cx('create_ad', 'create_ad_container_step3')}>
      {isCreatedState ? (
        <hgroup>
          <h2>광고만들기</h2>
          <h3>광고비 결제 예약 하기</h3>
          <p>
            본 단계에서는 바로 결제 되지 않으며, 광고 심사 후 승인이 완료되면, 지금 예약하신 광고비의 결제가 진행됩니다.
          </p>
        </hgroup>
      ) : !isComplete ? (
        <hgroup>
          <h2>광고 수정하기</h2>
          <h3>광고비 결제 예약하기</h3>
          <p>
            수정요청 시에는 광고비가 결제되지 않습니다. 접수요청서 서명 후 광고 프로젝트 최종 승인 시, 예약한 광고비가
            결제됩니다.
          </p>
        </hgroup>
      ) : (
        <hgroup>
          <h2>광고 수정하기</h2>
          <p>
            본 단계에서는 바로 결제 되지 않으며, 광고 심사 후 승인이 완료되면, 지금 예약하신 광고비의 결제가 진행됩니다.
          </p>
        </hgroup>
      )}
      {!isComplete ? (
        <section className={cx('ad_payment_list')}>
          {modifiedAdAccountList &&
            (modifiedAdAccountList.length > 0 ? (
              modifiedAdAccountList.map((val, idx) => (
                <div key={idx}>
                  <dl>
                    <dt>
                      {val.shared ? val.packageName : val.area}
                      <button
                        type="button"
                        className={cx('wz button circular mini')}
                        onClick={() => handleAdAccountListDelModalOpen(idx)}
                      >
                        삭제
                      </button>
                    </dt>
                    <dd className={cx('payment_list_date')}>
                      {/* 20-01-24<span className={cx('table_time')}>00:00:00</span> */}
                      {/* <span>{`${val.startAt.slice(2, 10)}, ${val.startAt.slice(-8)} ~ ${val.endAt.slice(2, 10)}, ${val.endAt.slice(-8)}`}</span> */}
                      <span>{`${val.startAt.slice(2, 10)} ~ ${val.endAt.slice(2, 10)}`}</span>
                      {
                        val?.price > 0 && (
                          <HolidayInPrice idx={idx} />
                        )
                      }
                    </dd>
                    {/* 미리보기 - 고정 이미지 인 경우 */}
                    <AdStaticPreview areaId={val.adCenterPubAreaId} />
                    {/* 앱푸시 || 이미지가 있는 경우 */}
                    {(val.adCenterPubAreaId === AreaType.messagePush || val.imgUrl) && (
                      <PreviewContainer
                        mainCopy={val.mainCopy}
                        keyword={val.keyword}
                        subCopy={val.subCopy}
                        imgUrl={val.imgUrl}
                        bgColor={val.bgColor}
                        area={val.area}
                        accountNum={val.accountNum}
                        adCenterPubAreaId={val.adCenterPubAreaId}
                        projectType={projectType}
                        lossText={lossText}
                      />
                    )}
                    {/* <dd className={cx('payment_list_price')}><strong>{comma(val.price)}원</strong></dd> */}
                  </dl>
                </div>
              ))
            ) : (
              <div>
                <dl className={cx('thad_listNull')}>
                  <i className={cx('icon error-o')}></i>
                  <p>등록된 광고 구좌가 없습니다.</p>
                </dl>
              </div>
            ))}
          {!isComplete && (
            <div className={cx('ad_payment')}>
              <div className={cx('payment_count')}>
                <h3>광고 예상 금액</h3>
                <p>
                  광고 금액 <em>{comma(totalPrice * (1 / 1.1))}원</em>
                  <br />
                  부가세 <em>{comma(totalPrice * (1 / 11))}원</em>
                </p>
                <strong>
                  {comma(totalPrice)}
                  <small>원</small>
                </strong>
              </div>
              <button type="button" className={cx('wz button primary')} onClick={handleOpenReservationPaymentDialog}>
                심사 요청 및 결제 예약하기
              </button>
              <p>
                <i className={cx('icon error-o')}></i>
                광고 프로젝트의 심사 요청이 접수되며, 광고비 결제가 예약됩니다. 심사 요청 접수 후, 약 3영업일 내 심사
                결과를 안내드립니다.
                <span className={cx('point_red')}>
                  아직 최종 결제 단계가 아닙니다. <br />
                  결제 예약 후, 심사 승인이 완료된 광고 건에 한하여 카드결제가 진행됩니다.
                </span>
              </p>
            </div>
          )}
          <div className={cx('buttonArea')}>
            <button
              type="button"
              className={cx('wz button less', 'button_historyBack')}
              onClick={() => handleClickPrevButton(1)}
            >
              <i className={cx('icon chevron-left')} />
              이전
            </button>
          </div>
        </section>
      ) : (
        <section className={cx('ad_paid')}>
          <p>
            <strong>
              수정한 내용으로 광고 심사요청 되었으며, <br />총 금액{' '}
              <em className={cx('point_primary')}>{comma(totalPrice)}원</em>이 결제 예약 완료되었습니다.
            </strong>
            1. 요청한 광고의 승인 여부는 3영업일 이내에 메이커님의 프로젝트 등록 시 기재하신 이메일을 통해 안내드립니다.
            <br />
            2. 설정한 날짜에 먼저 광고 요청한 메이커가 있을 경우, 일정 변경을 요청드릴 수 있습니다.
            <br />
            3. 2개 이상의 광고를 한 번에 결제 예약할 경우, 심사 승인 여부에 따라 결제가 일부만 진행될 수 있습니다.
            <br />
            4. 심사가 승인되면 광고비가 결제됩니다.
            <br />
          </p>
          <small>
            <strong>광고 심사 프로세스 알아보기</strong>
            <br />
            광고 심사 중일 때는 [요청]으로 표시됩니다.
            <br />
            광고 수정이 필요한 경우 [피드백]으로 표시됩니다.
            <br />
            광고 수정 중인 경우 [수정 중]으로 표시됩니다.
            <br />
            심사 후 접수요청서가 발송되면 [계약 중] 으로 표시됩니다.
            <br />
            광고가 최종 승인된 경우, [예정] 으로 표시됩니다.
            <br />
            광고가 최종 승인을 반려하면 [반려]로 표시됩니다.
            <br />
          </small>
          <p className={cx('point_red')}>결제 예약 후, 심사 승인이 완료된 광고 건에 한하여 카드결제가 진행됩니다.</p>
          <button type="button" className={cx('wz button primary', 'button_320')} onClick={handleClickGoToAdList}>
            MY 광고관리
          </button>
        </section>
      )}
    </div>
  )
}

export default ModifyAdStep2
