import React, { FC, FormEventHandler, ChangeEvent, useState, useCallback } from 'react'
import { Input, Button } from '@wadiz/waffle'
import type {
  KeywordListType,
  FunctionKeyword,
  FunctionKeywordValidation,
  KeywordValidationParam,
  KeywordValidationReturnType,
} from '@wad/api'
import { KeywordTable } from '../KeywordTable'
import { useSearchKeyword, useValidationKeyword } from '../queries'
import { useUnavailableKeywordNotification } from '../hooks'
import styles from './InputDirectKeyword.module.scss'

type InputDirectKeywordType = {
  fetchGetKeyword: FunctionKeyword
  fetchGetKeywordValidation: FunctionKeywordValidation
  validationData: KeywordValidationParam
  onSelectKeyword: (value: string) => void
}
export const InputDirectKeyword: FC<InputDirectKeywordType> = ({
  fetchGetKeyword,
  fetchGetKeywordValidation,
  validationData,
  onSelectKeyword,
}) => {
  const [localKeyword, setLocalKeyword] = useState('')
  const [searchKeyword, setSearchKeyword] = useState('')
  const [selectKeyword, setSelectKeyword] = useState('')
  const [errMessage, setErrMessage] = useState('')
  const [unavailableList, setUnavailableList] = useState<string[]>([])
  const { isInitialLoading: isLoading, data: searchData } = useSearchKeyword({
    fetchGetKeyword,
    keyword: searchKeyword,
    enabled: searchKeyword !== '',
  })
  const { contextHolder, noti } = useUnavailableKeywordNotification()
  const doSettled = useCallback(
    (validateResult: KeywordValidationReturnType, error) => {
      if (selectKeyword === '') {
        return
      }
      if (validateResult?.result === 'success') {
        onSelectKeyword(selectKeyword)
      } else {
        setErrMessage(validateResult?.message || '사용할 수 없는 키워드 입니다.')
        setUnavailableList([...unavailableList, selectKeyword])
        noti(validateResult?.message)
      }
      setSelectKeyword('')
    },
    [noti, onSelectKeyword, selectKeyword, unavailableList]
  ) as (validateResult?: KeywordValidationReturnType, error?: unknown) => void
  const { isInitialLoading: isValidateIn } = useValidationKeyword({
    fetchGetKeywordValidation,
    validationData: { ...validationData, keyword: selectKeyword } as KeywordValidationParam,
    enabled: selectKeyword !== '',
    onSettled: doSettled,
  })
  const data: KeywordListType = searchData || ({ count: 0, list: [] } as KeywordListType)
  const doDirectKeywordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLocalKeyword((e?.currentTarget?.value || '').replace(/\s/g, ''))
  }, [])
  const doQuery = () => {
    setSearchKeyword(localKeyword)
    setUnavailableList([])
    setErrMessage('')
  }
  const doSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e?.stopPropagation()
    e?.preventDefault()
    doQuery()
  }
  return (
    <>
      <form onSubmit={doSubmit}>
        <div className={styles.fieldContainer}>
          <Input
            maxLength={20}
            placeholder="키워드를 선택해주세요"
            onChange={doDirectKeywordChange}
            value={localKeyword}
            {...(errMessage !== '' ? { status: 'error' } : {})}
          />

          <Button variant="contained" color="primary" size="lg" onClick={doQuery} type="submit">
            조회
          </Button>
        </div>
      </form>
      {<div className={styles.errMessage}>{errMessage}</div>}
      <KeywordTable
        isLoading={isLoading}
        isHold={isValidateIn}
        data={data}
        onSelectKeyword={setSelectKeyword}
        unavailableList={unavailableList}
      />
      {contextHolder}
    </>
  )
}
