import React, { useState } from 'react';
import { Radio, Input, Space, Tooltip } from 'antd';
import cx from 'classnames'

import styles from './LandingURL.module.scss';

interface LandingURLProps {
  projectDetailURL: string;
  makerInfo: MakerInfo;
  isWadizAdmin: boolean;
  makerPageUrlSelected: boolean;
  onChangeLandingURL: (isProjectURL: boolean) => void;
}

interface MakerInfo {
  makerPageLandingUrl: string;
  visibleType: 'VISIBLE' | 'INVISIBLE' | 'NOTFOUND'
  selected: boolean;
}

const { Group } = Radio;
export const LandingURL: React.FC<LandingURLProps> = ({
  projectDetailURL,
  makerInfo,
  isWadizAdmin,
  makerPageUrlSelected,
  onChangeLandingURL,
}) => {
  const [isProjectURL, setProjectURL] = useState(!makerPageUrlSelected || false);
  return (
    <div>
      <Group
        defaultValue={isProjectURL}
        style={{ width: '100%' }}
        onChange={(event) => {
          setProjectURL(event.target.value)
          onChangeLandingURL(event.target.value)
        }}
      >
        <div style={{ marginBottom: 10 }}>
          <Space>
            <Radio className={styles.radio} value={true}>
              프로젝트 상세페이지
            </Radio>
            {makerInfo.visibleType !== 'VISIBLE' ? (
              <Tooltip title="메이커페이지를 찾을 수 없습니다. 메이커페이지 스튜디오를 확인해 주세요.">
                <Radio className={cx(styles.radio, styles.disabled)} value={false} disabled>
                  메이커페이지
                </Radio>
              </Tooltip>
            ) : (
              <Radio className={styles.radio} value={false}>
                메이커페이지
              </Radio>
            )}
          </Space>
          {isProjectURL ? (
            <>
              <Input disabled={!isWadizAdmin} value={projectDetailURL} style={{ marginBottom: 4 }} />
              <em style={{ fontSize: 13 }}>{'선택한 프로젝트의 URL 입니다. '}</em>
            </>
          ) : (
            <>
              <Input disabled={!isWadizAdmin} value={makerInfo.makerPageLandingUrl} style={{ marginBottom: 4 }} />
              <em style={{ fontSize: 13 }}>{'메이커페이지의 URL 입니다. '}</em>
            </>
          )}
        </div>
      </Group>
    </div>
  )
};
