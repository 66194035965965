import React from 'react'
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'
import { CalendarOIcon } from '@wadiz/waffle-icons'
import { Mobile, Default } from '../../responsive'
import 'react-dates/lib/css/_datepicker.css'
import styled from 'styled-components'

const DateWrapper = styled.div`
  .SingleDatePicker_picker {
    top: 50px !important;
    z-index: 4 !important;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .SingleDatePickerInput {
    border-radius: 0px;
    border: 0px;
  }
  .DateInput {
    position: relative;
    font-size: 15px;
    width: 100%;
    .DateInput_fang {
      top: 40px !important;
    }
  }
  .DateInput_input {
    width: 240px;
    height: 48px;
    line-height: 36px;
    font-size: inherit;
    padding-right: 2.35294em;
    font-weight: 300;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
    padding: 0 0.94118em;
    width: 100%;
    vertical-align: middle;
    box-sizing: border-box;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    transition-property: border-color, height, padding-right, color;
    transition-duration: 0.2s, 0s;
    &:hover,
    &:focus {
      border-color: #00c4c4;
    }
  }
  .SingleDatePickerInput_calendarIcon {
    position: absolute;
    top: 35%;
    right: 0;
    transform: translateY(-35%);
    transition-property: transform, opacity, color;
    transition-duration: 0.2s;
    padding-right: 0.33333em;
    padding-left: 0.33333em;
    width: auto;
    height: auto;
    color: rgba(0, 0, 0, 0.3);
    font-size: 1.41176em;
    pointer-events: none;
  }
  .DateInput_input[readonly] {
    cursor: pointer;
  }
  .error {
    .DateInput_input {
      border: solid 1px #f66 !important;
    }
  }
  .CalendarMonth_caption {
    padding-bottom: 45px !important;
  }
`

const CustomDatePicker = ({ onDateChange, onFocusChange, focused: focus, disabled, date, id, className }) => {
  return (
    <DateWrapper>
      <div className={className}>
        <Mobile>
          <SingleDatePicker
            // numberOfMonths={1}
            id={id}
            onDateChange={(d) => {
              onDateChange(d)
            }}
            onFocusChange={({ focused }) => {
              if (!focused) {
                return
              }
              onFocusChange({ focused })
            }}
            orientation={'vertical'}
            focused={focus}
            date={date}
            inputIconPosition="after"
            displayFormat="YY-MM-DD"
            isOutsideRange={() => false}
            customInputIcon={<CalendarOIcon />}
            readOnly
            disabled={disabled}
          />
        </Mobile>
        <Default>
          <SingleDatePicker
            // numberOfMonths={1}
            id={id}
            onDateChange={(d) => {
              onDateChange(d)
            }}
            onFocusChange={({ focused }) => {
              if (!focused) {
                return
              }
              onFocusChange({ focused })
            }}
            focused={focus}
            date={date}
            inputIconPosition="after"
            displayFormat="YY-MM-DD"
            isOutsideRange={() => false}
            customInputIcon={<CalendarOIcon />}
            readOnly
            disabled={disabled}
          />
        </Default>
      </div>
    </DateWrapper>
  )
}

export default CustomDatePicker
