import React from 'react'
import { Modal } from 'antd'
import { Button } from '@wadiz/waffle'
import { CheckIcon } from '@wadiz/waffle-icons'
import { Terms } from './Terms'
import styles from './TermsModal.module.scss'

export const TermsModal = ({ isOpen, onCancel, formid }) => (
  <Modal className={styles.antModal} open={isOpen} onCancel={onCancel} width={920} footer={false} maskClosable={false}>
    <section className={styles.container}>
      <h2 className={styles.heading}>광고 서비스 이용 약관에 동의해주세요.</h2>
      <div className={styles.contentHeading}>광고 서비스 이용 약관</div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <Terms />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          form={formid}
          type={'submit'}
          color={'primary'}
          variant={'contained'}
          fullWidth
          startIcon={<CheckIcon />}
          size={'md'}
        >
          동의합니다
        </Button>
      </div>
    </section>
  </Modal>
)
