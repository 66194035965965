import React from 'react';
import { Typography } from 'antd';
import { SelectGoods } from '../../AdRegistrationModal';
import { Goods } from '@center/api';


interface AdGoodsProps {
  defaultGoods: Goods;
  goodsList: Goods[];
  handleSelectGoods?: (goods: Goods) => void; // 수정쪽에서만 사용
  handleGoods: (goods: Goods, idx: number) => void;
  errorMsg?: string;
  disabled?: boolean;
}

const { Text } = Typography;
export const AdGoods: React.FC<AdGoodsProps> = ({
  defaultGoods,
  goodsList,
  handleSelectGoods,
  handleGoods,
  errorMsg,
  disabled,
}) => {
  return (
    <article>
      <label>광고상품</label>
      <SelectGoods
        defaultGoods={defaultGoods}
        goodsList={goodsList}
        onSelect={handleSelectGoods}
        onChange={handleGoods}
        disabled={disabled}
      />
      {
        errorMsg && (
          <Text type="danger">{errorMsg}</Text>
        )
      }
    </article>
  )
}
