import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import type { AreaIDType } from '@common'
import { PreviewSelector } from './PreviewSelector'
import { ImageMatterContainer } from './ImageMatterContainer'
import { CopyMatterContainer } from './CopyMatterContainer'
import { ColorMatterContainer } from './ColorMatterContainer'
import { KeywordMatterContainer } from './KeywordMatterContainer'
import styles from './AdvertisementMatter.module.scss'

/** TODO:
 * 이 모듈은 추후 수정이 필요하다 데이터를 상위 모듈에서 받지 않고 react query로 직접 받은 것으로 바뀌게 되면 대폭 수정되어야 할 것이다.
 * 또한 이 모듈을 그대로 둔 채 clone을 만들어서 react query를 받는 모듈들을 만드는 것도 좋을 것 같다.
 */
type AdvertisementMatterFunction = {
  getManualUrl: () => string
  areaType: AreaIDType
  /* image matter */
  haveImage: boolean | undefined
  imageUrl: string
  imageWidth: number
  imageHeight: number
  imageSize: number
  imageErrMsg: string
  isBadgeCombination: boolean
  onImageUploadSuccess: (imgUrl: string) => void
  onCloseImage: () => void
  /* color matter */
  haveColor: boolean | undefined
  /* main copy matter */
  haveMainCopy: boolean | undefined
  mainCopy: string | undefined
  /* sub copy matter */
  haveSubCopy: boolean | undefined
  subCopy: string | undefined
  /* keyword matter */
  haveKeyword: boolean | undefined
}

export const AdvertisementMatter: React.FC<AdvertisementMatterFunction> = ({
  getManualUrl,
  areaType,
  haveImage, // image matter
  imageUrl,
  imageWidth,
  imageHeight,
  imageSize,
  imageErrMsg,
  isBadgeCombination,
  onImageUploadSuccess,
  onCloseImage,
  haveColor, // color matter
  haveMainCopy, // main copy matter
  mainCopy,
  haveSubCopy, // sub copy matter
  subCopy,
  haveKeyword, // keyword matter
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>광고 소재</div>
        <Button type="text" icon={<DownloadOutlined />} onClick={() => window.open(getManualUrl())}>
          {'영역별 광고 소재 제작 가이드 확인하기'}
        </Button>
      </div>
      <section className={styles.matters}>
        <PreviewSelector
          areaType={areaType}
          mainCopy={mainCopy}
          subCopy={subCopy}
          imageUrl={imageUrl}
          onCloseImage={onCloseImage}
        />
        {haveImage && (
          <ImageMatterContainer
            imageUrl={imageUrl}
            size={imageSize}
            width={imageWidth}
            height={imageHeight}
            errMessage={imageErrMsg}
            isBadgeCombination={isBadgeCombination}
            onSuccess={onImageUploadSuccess}
          />
        )}
        {/* {haveColor && <ColorMatterContainer>d</ColorMatterContainer>}
        {haveMainCopy && <CopyMatterContainer>b</CopyMatterContainer>}
        {haveSubCopy && <CopyMatterContainer>c</CopyMatterContainer>}
        {haveKeyword && <KeywordMatterContainer>f</KeywordMatterContainer>} */}
      </section>
    </div>
  )
}

/* XXX: 노트 openGuide 라는 값을 찾아보았다. 23.08.11
  소재등록 각 영역의 헤더를 클릭하면 해당 위치를 기록해둔다. AccountCard -> Content -> Page 내 state 로...
  그리고 다시 openGuide라는 것으로 내려온다. 이 값을 사용해서 해당 섹션 즉 "광고상품", "광고기간"등의 active 라는 css class를 활성화 시킨다.
  문제는 active가 css에서 빠져있는 상황이라서...
  즉 openGuide 관련 코드는 필요없다.
*/
