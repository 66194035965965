// XXX: 사실 이렇게 작업하면 안되는 것인데, 시간이 되지 않아서 원래 코드의 내용을 그대로 잘라와서 함수를 구성 했음.
// AdModifyPage 의 파일 크기와 함수 크기 자체가 너무 큰 상황이기 때문...
// TODO: 고치고 싶다...
import { AreaType } from '@common'
export function validatorAllField(state) {
  let flag = false
  let toastMsg = ''
  let { projectId, adName, selectProjectErrMsg, changeAdNameErrMsg } = state
  let adAccountList = [...state.adAccountList]

  if (projectId === 0) {
    selectProjectErrMsg = '프로젝트를 선택하세요.'
    flag = true
  } else selectProjectErrMsg = ''
  if (adName === '') {
    changeAdNameErrMsg = '광고명을 입력하세요.'
    flag = true
  } else changeAdNameErrMsg = ''

  adAccountList.forEach((val, idx) => {
    if (!Object.prototype.hasOwnProperty.call(val, 'id') || val.selectedGoods.id === '') {
      adAccountList[idx].nameErrMsg = '광고 상품을 선택하세요.'
      flag = true
    } else adAccountList[idx].nameErrMsg = ''
    if (!Object.prototype.hasOwnProperty.call(val, 'startAt') || val.startAt === '') {
      adAccountList[idx].dateErrMsg = '광고 기간을 선택하세요.'
      flag = true
    } else adAccountList[idx].dateErrMsg = ''
    if ((!Object.prototype.hasOwnProperty.call(val, 'imgUrl') || val.imgUrl === '') && !(val.info.imageYn === 0)) {
      adAccountList[idx].imageErrMsg = '광고 소재를 등록해주세요.'
      flag = true
    } else adAccountList[idx].imageErrMsg = ''
    if ((!Object.prototype.hasOwnProperty.call(val, 'bgColor') || val.bgColor === '') && !(val.info.bgColorYn === 0)) {
      adAccountList[idx].backgroundColorErrMsg = '색상을 선택하세요.'
      flag = true
    } else adAccountList[idx].backgroundColorErrMsg = ''
    if (
      (!Object.prototype.hasOwnProperty.call(val, 'mainCopy') || val.mainCopy === '') &&
      !(val.info.mainCopyYn === 0)
    ) {
      adAccountList[idx].mainCopyErrMsg = '메인 카피를 등록하세요.'
      flag = true
    } else adAccountList[idx].mainCopyErrMsg = ''
    if (val.adCenterPubAreaId === AreaType.searchKeyword && !val.keyword) {
      flag = true
    }
    if ((!Object.prototype.hasOwnProperty.call(val, 'subCopy') || val.subCopy === '') && !(val.info.subCopyYn === 0)) {
      adAccountList[idx].subCopyErrMsg = '서브 카피를 등록하세요.'
      flag = true
    } else adAccountList[idx].subCopyErrMsg = ''
    if (
      (val.info.mainCopyYn === 1 && val.info.mainCopyLineNum <= 1 && val.mainCopy.indexOf('\n') >= 0) ||
      (val.info.subCopyYn === 1 && val.info.subCopyLineNum <= 1 && val.subCopy.indexOf('\n') >= 0)
    ) {
      toastMsg = '줄바꿈 여부를 확인하세요.'
    }
  })
  return { flag, toastMsg, adAccountList, selectProjectErrMsg, changeAdNameErrMsg }
}
