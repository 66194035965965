// eslint-disable-next-line
import React from 'react'
import wadizLogo from './logo-150x150.png'
import styles from './AppPushPreview.module.scss'

export const AppPushPreview: React.FC<{
  mainCopy: string
  subCopy: string
}> = ({ mainCopy, subCopy }) => (
  <section className={styles.screen}>
    <header className={styles.header}>
      <div>
        <img className={styles.wadizIcon} alt="와디즈 logo" data-type="image" src={wadizLogo} />
        <span>와디즈</span>
      </div>
    </header>
    <div className={styles.mainCopy}>{mainCopy}</div>
    <div className={styles.subCopy}>{subCopy}</div>
  </section>
)
