import React from 'react'
import { AreaType } from '@common'
import { makeFetch } from '../../../../../hooks/useFetch'
import { tAreaInfo } from './AreaItem'

export type tStore = {
  user: unknown
  dialog: unknown
}

type tAdType = {
  result: Array<tAreaInfo>
}

type tFetchResponse = {
  data: tAdType
}

export type tAccountItem = {
  accountNum: number
  advertiserDivision: number
  available: number
  id: number
  impNum: number
  impPeriod: number
  price: number
  pricingType: string
}

export type tGoodsItem = {
  adCenterPubAccountId: number
  discount: string
  goodsNum: number
  id: number
  impNum: number
  impPeriod: number
  price: number
}

const useAreaData = (store: tStore) => {

  // console.log('store : ', store);

  const {
    user: { isWadizAdmin,  isSuperUser },
    dialog: {
      dialogData: { projectType: pType, comingsoon: cSoon, campaignId, isStoreProject, projectStartDate },
    },
  } = store


  const advertiserType = `advertiser_type=${isWadizAdmin ? 1 : 0}`
  const projectType = `project_type=${pType}`
  const comingsoon = `&comingsoon=${cSoon}`
  const areaUrl = `pub-area/list?${advertiserType}&${projectType}&${comingsoon}`

  const [areaList, setAreaList] = React.useState<Array<tAreaInfo>>([] as Array<tAreaInfo>)
  const [accountList, setAccountList] = React.useState<Array<tAccountItem | null>>([])
  const [goodsList, setGoodsList] = React.useState<Array<Array<tGoodsItem | null>>>([])
  const [isLoading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    makeFetch('get', areaUrl)
      .then((response: tFetchResponse) => {
        const { data } = response
        if (!data) {
          return Promise.reject(new Error(`${areaUrl} 호출 중 데이터가 없이 응답되었습니다.`))
        }
        const { result } = data as tAdType
        const packageFollowAreaList = result.reduce((acc: Array<number>, info: tAreaInfo) => {
          if (!info.packagedId) {
            return acc
          }
          return [...acc, info.packagedId, info.id]
        }, [])
        const areaInfoList = result
          .map((info) => {
            if (packageFollowAreaList.findIndex((id) => id === info.id) > -1) {
              info.isPackaged = true
            }
            return info
          })
          .filter((info) => (info.id !== AreaType.searchKeyword && info.id !== AreaType.storeList) || isSuperUser)
        setAreaList(areaInfoList)

        Promise.all(
          areaInfoList.map((item) => {
            const pubAreaUrl = `pub-account/list?pub_area_id=${item.id}&${advertiserType}`
            return item.available === 1
              ? Promise.resolve({})
              : makeFetch('get', pubAreaUrl).then((res) => ({
                  ...res.data.result[0],
                  adCenterPubAreaId: item.id,
                }))
          })
        )
          .then((res: Array<tAccountItem>) => {
            setAccountList(res)
            return res
          })
          .then((resp) =>
            Promise.all([
              ...resp.map((item, idx) => {
                const pubAccountId = `pub_account_id=${item.id}`
                const typeString = `type=${!areaInfoList[idx].isPackaged ? 0 : 2}`
                const wdzCampaignId = `${pType === 3 ? 'wdz_store_project_id' : 'wdz_campaign_id'}=${campaignId}`
                const goodsUrl = `goods/list?${pubAccountId}&${advertiserType}&${typeString}&${wdzCampaignId}`
                return !item.id ? Promise.resolve([]) : makeFetch('get', goodsUrl).then((res) => res.data.result)
              }),
            ])
          )
          .then((res) => {
            setGoodsList(res)
            setLoading(false)
            return res
          })
      })
      .catch(() => {
        console.error('Promise all')
      })
  }, [areaUrl, advertiserType, setAreaList, setAccountList, setGoodsList, setLoading, pType, campaignId, isSuperUser])

  return { isLoading, areaList, accountList, goodsList, isStoreProject, projectType: pType, projectStartDate, comingsoon: cSoon }
}

export default useAreaData
