import React from 'react'
import { AreaType, AreaIDType } from '@common'
import { KeywordAdPreview } from '../KeywordAdPreview'
import { MainContentPreview } from '../MainContentPreview'
import { RecommendedContentsPreview } from '../RecommendedContentsPreview'
import { MainContentsBPlusPreview } from '../MainContentsBPlusPreview'

interface AdStaticPreviewProps {
  areaId: AreaIDType
}

export const AdStaticPreview: React.FC<AdStaticPreviewProps> = ({
  areaId
}) => {
  const PreviewImg = () => {
    switch (areaId) {
      case AreaType.mainContentA:
      case AreaType.mainContentB:
      case AreaType.mainContentC:
        return <MainContentPreview />;
      case AreaType.searchKeyword:
        return <KeywordAdPreview />;
      case AreaType.recommendedContents:
        return <RecommendedContentsPreview />;
      case AreaType.mainPlusContents:
      case AreaType.mainPlusBest:
      case AreaType.mainPlusFriend:
      case AreaType.mainPlusWish:
        return <MainContentsBPlusPreview />;
      default:
        return <></>;
    }
  }

  if (!areaId) return null;

  return (
    <article>
      <PreviewImg />
    </article>
  )
}
