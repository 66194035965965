// eslint-disable-next-line
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { AreaType } from '@common'
import { network } from '../../../network'
import { centerGetPubAreaAvailability } from '@wad/api'

import { closePopup, openAlertPopup, openWaffleModal } from '../../../reducers'

import AdAreaSection from './section/AdAreaSection/AdAreaSection.tsx'
import SingleDatePicker from '../../../components/CustomDatePicker'
import { toast } from 'react-toastify'

import bandBanner from '../../../assets/images/bandbanner.jpg'
import { BandLabelDescriptor } from './BandLabelDescriptor'
import { WadizOnlyDescriptor } from './WadizOnlyDescriptor'
import { PopularlyPopper } from './PopularlyPopper'

import className from 'classnames/bind'
import style from './AdAccount.module.scss'
const cx = className.bind(style)
class AdAccount extends PureComponent {
  initState = {
    selType: 0,
    selectedPositionName: '',
    selectedPositionManualUrl: '',
    isWadizLogin: 0,
    typeList: [],
    selAdAccount: -1,
    baseAccountList: [],
    adAccountList: [],
    accountList: [],
    selectedGoods: null, // 선택한 광고 상품
    goodsIdx: -1,
    goodsList: [], // 선택한 구좌에 대한 상품 정보 리스트
    isShow: false,
    activeItemIndex: 0,
    isMobile: document.body.offsetWidth <= 768,
    rangeStartDate: null, // 조회기간
    rangeStartDatePickerFocus: false,
    rangeEndDate: null, // 조회기간
    rangeEndDatePickerFocus: false,
    adStartDate: null, // 광고기간
    adStartDatePickerFocus: false,
    adEndDate: null, // 광고기간
    adEndDatePickerFocus: false,
    openDate: '',
    projectType: 0,
    comingsoon: 0,
    areaInfo: {},
    accountInfo: {},
    packagedInfo: {},
    disabledNextConfirm: true,
    campaignId: 0,
  }

  state = this.initState

  componentDidMount() {
    const isWadizLogin = this.props.user.isWadizAdmin ? 1 : 0
    const { dialogData } = this.props.dialog
    const availableIndex = -1
    this.setState({
      isWadizLogin,
      isStoreProject: dialogData.isStoreProject,
      selType: availableIndex,
      openDate: dialogData.openDate,
      projectType: dialogData.projectType,
      comingsoon: dialogData.comingsoon,
      baseAccountList: dialogData.accountList,
      rangeStartDate: moment(dialogData.openDate),
      rangeEndDate: moment(dialogData.openDate).add(6, 'day'),
      activeItemIndex: availableIndex,
      campaignId: dialogData.campaignId,
    })
  }

  static getDerivedStateFromProps = (nextProps) => {
    if (nextProps.dialog.dialogType === 'adAccount') {
      return { isShow: true }
    }
    return null
  }

  handleClose = () => {
    this.props.closePopup()
    this.setState({ ...this.initState })
  }

  handleConfirm = () => {
    const { dialogData } = this.props.dialog
    console.log(this.state);
    dialogData.confirmFunc({
      returnState: { ...this.state },
    })
    this.handleClose()
  }
  isFundingHomeList = (id) => id === AreaType.fundingList
  doCheckEndDate = (startAt, endAt) => {
    const {
      areaInfo: { id },
      projectType,
      campaignId,
    } = this.state
    this.setState({
      disabledNextConfirm: true,
    })
    return centerGetPubAreaAvailability({
      startAt: startAt.format('YYYY-MM-DD'),
      endAt: endAt.format('YYYY-MM-DD'),
      pubAreaId: id,
      projectType,
      campaignId,
    })
      .then((res) => {
        // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
        const {
          data: { availability },
        } = res
        if (availability === 'Closed') {
          toast('설정하신 기간의 예약이 마감되었습니다. 다른 기간을 선택해주세요.')
        }
        if (availability === 'Imminent' && this.isFundingHomeList(id)) {
          this.props.openAlertPopup({
            alertData: {
              title: '예약 마감 확률이 높은 기간입니다.',
              content: (
                <p>
                  설정하신 기간에 다른 메이커님의 선요청된 광고 심사 내역이 존재합니다. 심사 요청 및 결제 예약은
                  가능하나 예약이 마감될 경우 기간 변경을 요청드릴 수 있습니다
                </p>
              ),
              confirmText: '예, 알겠습니다.',
              confirmFunc: () => {
                console.info('doCheckEndDate')
              },
            },
          })
          // this.props.openWaffleModal({
          //   title: '예약 마감 확률이 높은 기간입니다.',
          //   message:`설정하신 기간에 다른 메이커님의 선요청된 광고 심사 내역이 존재합니다. 심사 요청 및 결제 예약은
          //       가능하나 예약이 마감될 경우 기간 변경을 요청드릴 수 있습니다`,
          //   confirmLabel: '예, 알겠습니다.',
          // })
        }
        if (availability === 'Unavailable') {
          this.props.openWaffleModal({
            title: '다른 기간을 선택해 주세요.',
            message: '같은 기간에 이미 진행중인 광고가 있습니다',
          })
        }
        return availability
      })
      .finally(() => {
        this.setState({
          disabledNextConfirm: false,
        })
      })
  }

  // 광고 기간 핸들러
  onChangeDateRange = (name, value) => {
    const todayDate = moment(moment(new Date()).format('YYYY-MM-DD'))
    if (name === 'rangeStartDate') {
      const valueDate = moment(moment(value).format('YYYY-MM-DD'))
      if (valueDate < todayDate) {
        toast('오늘 이후 날짜를 선택해주세요.')
        return
      }

      let { rangeEndDate } = this.state
      rangeEndDate = rangeEndDate || value
      if (value.toDate().getTime() > rangeEndDate.toDate().getTime()) {
        rangeEndDate = value
      }
      this.setState({
        [name]: value,
        rangeEndDate,
        adStartDate: null,
        adEndDate: null,
        selType: -1,
        selAdAccount: -1,
        adAccountList: [],
        selectedPositionName: '',
        selectedPositionManualUrl: '',
      })
    } else if (name === 'rangeEndDate') {
      let { rangeStartDate } = this.state
      rangeStartDate = rangeStartDate || value
      if (rangeStartDate.toDate().getTime() > value.toDate().getTime()) {
        rangeStartDate = value
      }
      const valueDate = moment(moment(value).format('YYYY-MM-DD'))
      if (valueDate < todayDate) {
        toast('오늘 이후 날짜를 선택해주세요.')
        return
      }
      this.setState({
        [name]: value,
        rangeStartDate,
        adStartDate: null,
        adEndDate: null,
        selType: -1,
        selAdAccount: -1,
        adAccountList: [],
        selectedPositionName: '',
        selectedPositionManualUrl: '',
      })
    } else if (name === 'adStartDate' || name === 'adEndDate') {
      let { adStartDate, adEndDate } = this.state
      const selectedGoods = { ...this.state.selectedGoods }
      adStartDate = value
      adEndDate = moment(adStartDate).add(selectedGoods.impPeriod - 1, 'day')
      const valueDate = moment(moment(value).format('YYYY-MM-DD'))
      if (valueDate < todayDate) {
        toast('오늘 이후 날짜를 선택해주세요.')
        return
      }
      // if (valueDate.format('YYYY-MM-DD') === todayDate.format('YYYY-MM-DD')) {
      //   this.props.openAlertPopup({
      //     alertData: {
      //       title: '설정한 광고 시작일이 매우 촉박합니다.',
      //       content: (
      //         <p>
      //           <em className={cx('point_primary')}>
      //             {adStartDate.year()}년 {adStartDate.month() + 1}월 {adStartDate.date()}일
      //           </em>
      //           은 광고 시작일로 설정 가능하지만, 심사 일정을 고려하여 안정적인 광고 진행을 위해{' '}
      //           <em className={cx('point_primary')}>광고 시작일을 오늘부터 3 영업일 이후</em>로 설정하기를 권장합니다.
      //         </p>
      //       ),
      //       confirmText: '예, 알겠습니다.',
      //       confirmFunc: () => {},
      //     },
      //   });
      // }
      this.doCheckEndDate(adStartDate, adEndDate).then((availability) => {
        // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
        if (availability === 'Closed') {
          return
        }
        this.setState(
          {
            adStartDate,
            adEndDate,
            adStartDatePickerFocus: false,
            adEndDatePickerFocus: false,
          },
          () => {
            this.handleClickAddAdAccount()
          }
        )
      })
    }
  }

  // DatePicker focus 핸들러
  onFocusChange = (name, value) => {
    this.setState({ [name]: value })
  }

  onSelectGoods = (e) => {
    const goodsList = [...this.state.goodsList]
    const idx = Number(e.target.value)
    const selectedGoods = goodsList[idx]
    const adStartDate = this.state.rangeStartDate
    const adEndDate = moment(adStartDate).add('day', selectedGoods.impPeriod - 1)

    this.doCheckEndDate(adStartDate, adEndDate)
      .then((availability) => {
        // Available:가능, Imminent:마감 임박, Closed:마감, Unavailable: 이전 광고와 연계하여 광고 할 수 없음.
        this.setState({
          disabledNextConfirm: availability === 'Closed',
        })
      })
      .finally(() => {
        this.setState(
          {
            selectedGoods,
            adStartDate,
            adEndDate,
            goodsIdx: idx,
          },
          () => {
            this.handleClickAddAdAccount()
          }
        )
      })
  }

  // 확정된 광고와 겹쳐지지 않는지 체크
  handleCheckIsOverlapDate = () => {
    const { adStartDate, adEndDate, selAdAccount } = this.state
    const adAccountList = [...this.state.adAccountList]
    const adCenterPubAccountId = adAccountList[selAdAccount].id

    return new Promise((resolve, reject) => {
      const url = `creative/status/validation?advertiser_type=${this.props.user.isWadizAdmin ? 1 : 0
        }&start_date=${adStartDate.year()}-${adStartDate.month() + 1
        }-${adStartDate.date()} 00:00:00&end_date=${adEndDate.year()}-${adEndDate.month() + 1
        }-${adEndDate.date()} 00:00:00&pub_account_id=${adCenterPubAccountId}`
      network.callApi({
        url,
        method: 'GET',
        successFunc: ({ result }) => {
          if (result) {
            resolve(result.validation)
            return result.validation
          }
          return 0
        },
        failFunc: (error) => {
          reject(error)
        },
      })
    })
  }

  handleClickAddAdAccount = () => {
    const { adStartDate, adEndDate, goodsList, goodsIdx, accountInfo, areaInfo, selectedGoods, packagedInfo } =
      this.state
    const { impPeriod, impNum, price, discount, goodsNum } = selectedGoods

    const selectedAdAccount = {
      ...accountInfo,
      positionName: areaInfo.area,
      startAt: adStartDate.format('YYYY-MM-DD'),
      endAt: (adEndDate || moment()).format('YYYY-MM-DD'),
      goodsIdx,
      goodsid: goodsList[goodsIdx].id,
      goodsList: [...goodsList],
      manualUrl: areaInfo.manualUrl,
      selectedGoods,
      impPeriod,
      impNum,
      price,
      discount,
      goodsNum,
      packagedInfo,
      isAdded: true,
    }
    this.setState({ accountList: [selectedAdAccount] })
  }

  comma = (val) => {
    if (typeof val === 'string') return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (typeof val === 'number') return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return val
  }

  doChangeAreaInfo = (areaInfo, accountInfo, goodsList, packagedInfo) => {
    console.log('change area', areaInfo, accountInfo, goodsList, packagedInfo)
    this.setState({
      areaInfo,
      accountInfo,
      goodsList,
      packagedInfo,
      goodsIdx: -1,
      selectedGoods: null,
      adStartDate: null,
      adEndDate: null,
    })
  }

  render() {
    const { handleClose, handleConfirm, onChangeDateRange, onFocusChange, onSelectGoods, comma, doChangeAreaInfo } =
      this
    const {
      isShow,
      accountList,
      adStartDate,
      adStartDatePickerFocus,
      adEndDate,
      adEndDatePickerFocus,
      goodsList,
      selectedGoods,
      goodsIdx,
      accountInfo,
      disabledNextConfirm,
      areaInfo,
    } = this.state
    return (
      isShow && (
        <>
          <div className={cx('dialog_shadow')} onClick={handleClose}></div>
          <div id={cx('adAccount')} className={cx('dialog_wrap', 'typePopup')}>
            <header>
              <div className={cx('dialog_close')}>
                {/* Close Button Area */}
                <button className={cx('wz button less')} onClick={handleClose}>
                  <i className={cx('icon close')} />
                </button>
              </div>
              <div className={cx('dialog_title')}>
                {/* Title Area */}
                <h3>광고 등록하기</h3>
              </div>
            </header>
            <main className={cx('dialog_content', 'dialog_adAccount')}>
              <AdAreaSection onChangeAreaInfo={doChangeAreaInfo} />
              <section className={cx('account_select')}>
                <div className={cx('account_select_goods')} style={{ position: 'relative' }}>
                  <h4>광고 상품 선택 & 기간 설정</h4>
                  <p>
                    선택하신 광고영역의 상품 리스트 입니다. <br />
                    원하시는 상품을 선택해 주세요.{' '}
                  </p>
                  <div className={cx('account_select_content')}>
                    {!goodsList || goodsList === 'fail' || goodsList.length === 0 ? (
                      <p className={cx('recommend_msg')}>아직 광고 상품이 선택되지 않았습니다.</p>
                    ) : (
                      <div className={cx('account_select_button')}>
                        <PopularlyPopper goodsList={goodsList}>
                          '인기'라벨 적용한 광고는 1.25배 더 많은 알림신청을 받고 있어요!
                        </PopularlyPopper>
                        <label className={cx('wz outline block dropdown')}>
                          <select id="goodsList" onChange={onSelectGoods} value={goodsIdx}>
                            {!selectedGoods && (
                              <option value="" hidden>
                                상품을 선택하세요{' '}
                              </option>
                            )}
                            {goodsList.map((val, idx) =>
                              accountInfo.pricingType === 'CPT' ? (
                                !val.discount ? (
                                  <option key={idx} value={idx}>{`${val.impPeriod}일 ${comma(val.price)}원`}</option>
                                ) : (
                                  <option key={idx} value={idx}>{`${val.impPeriod}일 ${comma(val.price)}원 (${val.discount
                                    })`}</option>
                                )
                              ) : !val.discount ? (
                                <option key={idx} value={idx}>{`${val.impPeriod}일 ${comma(val.price)}원 ${comma(
                                  val.impNum
                                )}회 노출`}</option>
                              ) : (
                                <option key={idx} value={idx}>{`${val.impPeriod}일 ${comma(val.price)}원 ${comma(
                                  val.impNum
                                )}회 노출 (${val.discount})`}</option>
                              )
                            )}
                          </select>
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                {/* 가변 라벨영역  - 시작 */}
                <div>
                  <BandLabelDescriptor
                    bandBanner={bandBanner}
                    isBandTypePopularity={selectedGoods && selectedGoods.badgeType === 'Popularity'}
                  />
                  <WadizOnlyDescriptor
                    isWadizOnly={areaInfo?.id === AreaType.storeHome || areaInfo?.id === AreaType.storeList}
                  />
                </div>
                {/* 가변 라벨영역  - 끝 */}

                <div className={cx('account_select_goods')}>
                  <p>선택하신 광고 상품의 진행 기간을 설정해 주세요.</p>
                  <div className={cx('account_select_content')}>
                    {!(adStartDate && adEndDate) ? (
                      <p className={cx('recommend_msg')}>아직 광고 기간이 설정되지 않았습니다.</p>
                    ) : (
                      <div className={cx('account_select_date')}>
                        <div className={cx('date_range_input')}>
                          <SingleDatePicker
                            id={'adStartDate'}
                            onDateChange={(date) => {
                              onChangeDateRange('adStartDate', date)
                            }}
                            onFocusChange={({ focused }) => {
                              if (!focused) {
                                return
                              }
                              onFocusChange('adStartDatePickerFocus', focused)
                            }}
                            focused={adStartDatePickerFocus}
                            date={adStartDate}
                            isOutsideRange={() => false}
                          />
                          <span className={cx('range_ico')}>~</span>
                          <SingleDatePicker
                            id={'adEndDate'}
                            onDateChange={(date) => {
                              onChangeDateRange('adEndDate', date)
                            }}
                            onFocusChange={({ focused }) => {
                              if (!focused) {
                                return
                              }
                              onFocusChange('adEndDatePickerFocus', focused)
                            }}
                            focused={adEndDatePickerFocus}
                            date={adEndDate}
                            isOutsideRange={() => false}
                          />
                        </div>
                        <em style={{ fontWeight: 'bold' }} className={cx('helper')}>
                          1. 광고는 1일 0시~ 24시 (24시간) 기준으로 노출됩니다.
                        </em>
                        <br />
                        <em style={{ fontWeight: 'bold' }} className={cx('helper')}>
                          2. 다른 메이커님의 광고 심사 요청 내역이 존재하거나 예약이 마감된 기간이 있을 수 있습니다.
                        </em>
                        <br />
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </main>
            <footer className={cx('dialog_button')}>
              {/* Button Area */}
              <button
                className={cx('wz button primary')}
                onClick={handleConfirm}
                disabled={disabledNextConfirm || accountList.length === 0}
              >
                다음
              </button>
            </footer>
          </div>
        </>
      )
    )
  }
}

export default connect(({ user, dialog, waffleModal }) => ({ user, dialog, waffleModal }), {
  openAlertPopup,
  closePopup,
  openWaffleModal,
})(AdAccount)
