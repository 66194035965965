//http://rc-api01:9990/ad-admin/swagger-ui.html#/
import { api } from '../api/api'

type AdTermsAgreement = {
  id: number
  adMakerId: number
  agreementDate: string
}

//rc-api01:9990/ad-center/swagger-ui.html?urls.primaryName=AdPlant#/operations/ad-plant-controller/getAdTermsAgreementsByAdMakerUsingGET
type TermsAgreementParameter = {
  adMakerId: number
}
type TermsAgreementFunctionType = (param: TermsAgreementParameter) => Promise<{ data: AdTermsAgreement[] }>
export const centerGetTermsAgreements: TermsAgreementFunctionType = ({ adMakerId }) =>
  api('GET', `/makers/${adMakerId}/terms/agreements`)

export const centerPostTermsAgreements: TermsAgreementFunctionType = ({ adMakerId }) =>
  api('POST', `/makers/${adMakerId}/terms/agreements`)

type StoreProjectInfoParameter = {
  apiHost: string
  adMakerId: number
  wdzStoreProjectId: number
}
type Campaign = {
  campaignId: number //	integer($int64)
  campaignType: 'EQUITY' | 'REWARD' | 'STORE'
  comingSoon: boolean
  landingUrl: string
  title: string
  wadizOnly: boolean
}
export type WdzStoreProject = {
  // campaign: Campaign
  id: number //	integer($int64) 와디즈 스토어 프로젝트 Id
  title: string // 와디즈 스토어 프로젝트명
  wadizOnly: boolean // 와디즈 Only 여부
}
type StoreProjectInfoFunctionType = (param: StoreProjectInfoParameter) => Promise<WdzStoreProject>
export const centerGetStoreProjectInfo: StoreProjectInfoFunctionType = async ({
  adMakerId,
  wdzStoreProjectId,
}) => api('GET', `/makers/${adMakerId}/store-projects/${wdzStoreProjectId}`)
