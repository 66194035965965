import React from 'react';
import { AreaIDType, checkMakerLandingURL } from '@common';
import { Input, Typography } from 'antd';
import { LandingURL } from '../../LandingURL';

interface AdLandingUrlProps {
  adCenterPubAreaId: AreaIDType;
  makerInfo: MakerInfo;
  projectDetailURL: string;
  makerPageUrlSelected: boolean;
  isWadizAdmin: boolean;
  handleLandingURL?: (isProjectURL: boolean) => void;
  inputTextChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface MakerInfo {
  makerPageLandingUrl: string;
  visibleType: 'VISIBLE' | 'INVISIBLE' | 'NOTFOUND'
  selected: boolean;
}

export const AdLandingUrl: React.FC<AdLandingUrlProps> = ({
  adCenterPubAreaId,
  makerInfo,
  projectDetailURL,
  makerPageUrlSelected,
  isWadizAdmin,
  handleLandingURL,
  inputTextChange,
}) => {
  return (
    <article>
      <label>{'광고 상품 클릭 시 어떤 화면으로 이동할까요?'}</label>
      {checkMakerLandingURL(adCenterPubAreaId)
        ? makerInfo &&
          handleLandingURL && (
            <LandingURL
              projectDetailURL={projectDetailURL}
              makerInfo={makerInfo}
              makerPageUrlSelected={makerPageUrlSelected}
              isWadizAdmin={isWadizAdmin}
              onChangeLandingURL={handleLandingURL}
            />
          )
        : inputTextChange && (
            <>
              <Input
                type="url"
                name="landingUrl"
                value={projectDetailURL}
                onChange={(e) => inputTextChange(e)}
                disabled={!isWadizAdmin}
                style={{ marginBottom: 4 }}
              />
              <em style={{ fontSize: 13, color: '#90949c' }}>{'선택한 프로젝트의 URL 입니다. '}</em>
            </>
          )}
      <em style={{ fontSize: 13, color: '#90949c' }}>
        {'광고 상품 클릭 시 위 입력 상자에 적힌 주소(url)로 사용자 화면이 이동합니다.'}
      </em>
    </article>
  )
}
