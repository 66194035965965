export const AreaType = {
  mainHome: 10000001, // 1 "메인 패키지-메인 홈 배너",
  mainBanner: 10000012, // 2 "메인 패키지-MY띠 배너",
  mainContentA: 10000014, // 3 "메인 콘텐츠A",
  mainContentB: 10000016, // 4 "메인 콘텐츠B",
  mainContentC: 10000017, // 5 "메인 콘텐츠C",
  fundingList: 10000009, // 6 "펀딩 리스트",
  fundingHome: 10000008, // 7 "펀딩 홈 배너",
  comingSoonHome: 10000010, // 8 "오픈예정 패키지-오픈예정 홈 배너",
  comingSoonList: 10000011, // 9 "오픈예정 패키지-오픈예정 리스트",
  storeList: 10000006, // 10 "스토어 리스트",
  mainBoardDeprecated: 10000015, // 11  # (구) 메인 보드
  storeHome: 10000005, //12  # 스토어 홈 배너
  preOrderBanner: 10000018, // 13 "프리오더 패키지-프리오더 홈 배너",
  preOrderList: 10000019, // 14 "프리오더 패키지-프리오더 홈 리스트",
  searchKeyword: 10000020, // "검색어 광고",
  mainBoard: 10000021, // 메인 보드
  recommendedContents: 10000022, // 상세 - 추천 콘텐츠
  searchBoard: 10000023, // 검색 결과 - 리스트
  /* 푸시 광고 */
  messagePush: 10000004, // 10 "메시지 앱 푸시",
  kakaoMalePush: 10000031, // "카카오 메인푸시 - 남성 메인",
  kakaoMaleThumbnailPush: 10000032, // "카카오 메인푸시 - 남성 썸네일",
  kakaoFemalePush: 10000033, // "카카오 메인푸시 - 여성 썸네일",
  kakaoFemaleThumbnailPush: 10000034, // "카카오 메인푸시 - 여성 썸네일",
  kakaoFanPush: 10000035, // 카카오 Fan 푸시 - 메인
  kakaoFanThumbnailPush: 10000036, // 카카오 Fan 푸시 - 메인
  kakaoSolePush: 10000038, // 카카오 단독 푸시 - 1만
  kakaoCarouselCouponPush: 10000040, // 카카오 캐러셀 푸시 - 쿠폰
  kakaoCarouselRollingPush: 10000041, // 카카오 캐러셀 푸시 - 쿠폰
  popupPush: 10000043, // 앱 메인 팝업 푸시
  // 메인 콘텐츠B+
  mainPlusContents: 10000024, // 메인 콘텐츠B 플러스-메인 콘텐츠B / MMCBA
  mainPlusBest: 10000025, // 메인 콘텐츠B 플러스-베스트(실시간 랭킹) / MMCBR
  mainPlusFriend: 10000026, // 메인 콘텐츠B 플러스-친구 / MMCBF
  mainPlusWish: 10000027, // 메인 콘텐츠B 플러스-찜하기 / MMCBW
  // 멀티보드
  multiMainBoardContents: 10000028, // 멀티보드-메인보드 / MHBA
  multiDetailContents: 10000029, // 멀티보드-상세페이지 / MHBD
  multiMyPage: 10000030, // 멀티보드-마이페이지 / MHBM
  // [deprecated]
  deprecated1: 10000003,
  deprecated2: 10000013,
  //  [미할당]
  unassigned1: 10000002, // # 메인 프로젝트 보러가기
  unassigned2: 10000007, // # 투자 오픈예정 프로젝트

  // remindMessagePush: 10000044, // 리마인드 메시지
  // appMainPopupPush: 10000043, // 앱 메인 팝업
  // appPush: 10000004, //10 "메시지 앱 푸시",
  // kakaotalkRegularManMainPush: 10000031, // 카카오톡 정기 - 남성 메인
  // kakaotalkRegularManThumbnailPush: 10000032, // 카카오톡 정기 - 남성 썸네일
  // kakaotalkRegularWomanMainPush: 10000033, // 카카오톡 정기 - 여성 메인
  // kakaotalkRegularWomanThumbnailPush: 10000034, // 카카오톡 - 여성 썸네일
  // kakaotalkFanMainPush: 10000035, // 카카오톡 - FAN 메인
  // kakaotalkFanThumbnailPush: 10000036, // 카카오톡 - FAN 썸네일
  // kakaotalkSingle2Push: 10000037, // 카카오톡 단독 2만
  // kakaotalkSingle1Push: 10000038, // 카카오톡 단독 1만
  // kakaotalkSingle4000Push: 10000039, // 카카오톡 단독 4000
  // kakaotalkCarouselCouponRollingPush: 10000040, // 카카오톡 케러셀 쿠폰
  // kakaotalkCarouselRollingPush: 10000041, // 카카오톡 케러셀 롤링
  // kakaotalkCarouselNormalPush: 10000042, // 카카오톡 케러셀 일반
} as const
export type AreaIDType = (typeof AreaType)[keyof typeof AreaType]

type SetFunctionType = (list: AreaIDType[], id: number) => boolean
const containSet: SetFunctionType = (list, id) => list.findIndex((item: number) => item === id) > -1

type CheckDisabledFunctionType = (id: number) => boolean
const disabledAreaWhenStoreProject: CheckDisabledFunctionType = (id) =>
  containSet(
    [
      AreaType.fundingList,
      AreaType.fundingHome,
      AreaType.comingSoonHome,
      AreaType.comingSoonList,
      AreaType.mainBoardDeprecated,
      AreaType.preOrderBanner,
      AreaType.preOrderList,
    ],
    id
  )
const disabledAreaWhenPreOrder: CheckDisabledFunctionType = (id) =>
  containSet(
    [
      AreaType.storeHome, //
      AreaType.storeList, //
    ],
    id
  )
const disabledAreaWhenTheOthers: CheckDisabledFunctionType = (id) =>
  containSet(
    [
      AreaType.storeHome, //
      AreaType.storeList, //
      AreaType.preOrderBanner, //
      AreaType.preOrderList, //
    ],
    id
  )

export const checkMakerLandingURL = (pubAreaId: AreaIDType) => {
  // 메이커의 랜딩 URL을 사용하는 리스트
  const makerLandingUrlList = [
    AreaType.mainBoard,
    AreaType.searchBoard,
    AreaType.multiMainBoardContents,
    AreaType.multiMyPage,
    AreaType.multiDetailContents,
  ]
  return makerLandingUrlList.some((i) => i === pubAreaId)
}

/*
  TODO: 다음 주석으로 처리한 조건은 2023.02.03에 원복되었으나 추후 작업을 위해 남겨둔다.
  2023.1.31 펀딩/프리오더 광고가 진행중일 경우, 오픈예정 패키지 광고는 disable 처리 되어야 한다.
  조건 정리 (펀딩/프리오더) -> projectStartDate는 콘텐츠 확인중 상태에 대한 구분 문제로 비교처리는 하지 않되, 값은 컴포넌트에 유지하고 차후 필요시 비교 로직 작업 진행.
    - 오픈예정 disable : comingsoon이 0이면, 프로젝트 진행중
    - 오픈예정 enable : comingsoon이 1이면, 프로젝트 오픈예정
*/
// const reallocAvailable =
// (projectType == 2 || projectType == 4) && comingsoon == 0 && (10000010 === id || 10000011 === id) ? 1 : available

type CheckDisabledAreaFunctionType = (param: { isStoreProject: boolean; isPreOrder: boolean; id: number }) => boolean
export const checkAreaDisabled: CheckDisabledAreaFunctionType = ({ isStoreProject, isPreOrder, id }) =>
  isStoreProject
    ? disabledAreaWhenStoreProject(id)
    : isPreOrder
    ? disabledAreaWhenPreOrder(id)
    : disabledAreaWhenTheOthers(id)

export const isPushAd = (type: number) => {
  return (
    type === AreaType.messagePush ||
    type === AreaType.kakaoFemalePush ||
    type === AreaType.kakaoFemaleThumbnailPush ||
    type === AreaType.kakaoMalePush ||
    type === AreaType.kakaoMaleThumbnailPush ||
    type === AreaType.kakaoCarouselCouponPush ||
    type === AreaType.kakaoCarouselRollingPush ||
    type === AreaType.kakaoFanPush ||
    type === AreaType.kakaoFanThumbnailPush ||
    type === AreaType.kakaoSolePush ||
    type === AreaType.popupPush
  )
}
