import React, { useCallback, useState, FC } from 'react'
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Modal } from 'antd'
import { Button, WYSIWYGEditorView } from '@wadiz/waffle'
import { useNotificationQuery } from './_queries/useNotificationQuery'
import styles from './NotificationModal.module.scss'

dayjs.extend(isSameOrBefore)
const NotificationModalImpl: FC<{ isOpen: boolean; onClose: () => void; model: string | undefined }> = ({
  isOpen,
  onClose,
  model,
}) => (
  <Modal open={isOpen && !!model && model.length > 0} width={740} footer={false} onCancel={onClose}>
    <section className={styles.container}>
      <WYSIWYGEditorView model={model} />
      <div className={styles.buttonContainer}>
        <Button type="button" color="primary" variant="contained" size="lg" fullWidth onClick={onClose}>
          확인
        </Button>
      </div>
    </section>
  </Modal>
)

export const NotificationModal: FC = () => {
  const { isInitialLoading, data: notificationList } = useNotificationQuery()
  const cookieValue = 'shown'
  const cookieName = 'notification-db-escalation-613'
  const [cookies, setCookie] = useCookies([cookieName])
  const date = dayjs()
  const [isOpen, setOpen] = useState(cookies[cookieName] !== cookieValue /* && date.isSameOrBefore('2023-05-31') */)

  const onClose = useCallback(() => {
    setOpen(false)
    setCookie(cookieName, cookieValue, { expires: date.add(1, 'day').toDate() })
  }, [date, setCookie])
  if (isInitialLoading) {
    return null
  }
  return <NotificationModalImpl {...{ isOpen, onClose }} model={notificationList?.[0]?.content} />
}
