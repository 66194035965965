import React, { useState, useMemo } from 'react';
import { Input, Typography } from 'antd';
import { AreaType, AreaIDType } from '@common';

import styles from './AdCopy.module.scss'

interface AdCopyProps {
  title: string;
  defaultValue: string;
  rows: number;
  maxLength: number;
  errorMsg?: string;
  handleCopy: (value: string) => void;
  areaId: AreaIDType;
  children: React.ReactNode;
}

const { TextArea } = Input;
const { Text } = Typography;

// 특수문자 허용 지면
const passSpecialString: AreaIDType[] = [
  AreaType.messagePush,
]

export const AdCopy: React.FC<AdCopyProps> = ({
  title,
  defaultValue,
  rows,
  maxLength,
  errorMsg,
  handleCopy,
  children,
  areaId,
}) => {
  const [value, setValue] = useState<string>(defaultValue);
  const isPassSpecialString = useMemo(() => passSpecialString.includes(areaId), [areaId])

  const handleTextarea = (text: string) => {
    // 특수 문자 중 ,.% 는 허용
    const regExp = /[\{\}\[\]\/?;:|\)*~`!^\-_+<>@\#$&\\\=\(\'\"]/g;
    if (regExp.test(text) && !isPassSpecialString) {
      return;
    }
    setValue(text);
    handleCopy(text);
  }

  const onPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // TextArea의 줄 수는 rows를 넘길 수 없다.
    if (value.split('\n').length >= rows) {
      e.preventDefault();
    }
  };

  return (
    <article className={styles.container}>
      <label>{title} (광고 미리보기를 통해 {title}의 위치를 확인하세요.)</label>
      {children}
      <div>
        <TextArea
          value={value}
          onChange={(e) => handleTextarea(e.target.value)}
          onPressEnter={(e) => onPressEnter(e)}
          maxLength={maxLength}
          rows={rows || 1}
          showCount
          className={styles.textCopy}
        />
        {
          !isPassSpecialString && (
            <Text
              type="secondary"
              style={{ fontSize: 12, paddingLeft: 40 }}
            >(띄어쓰기 포함 {maxLength / (rows || 1)}자, {rows || 1}줄) ,.% (쉼표, 마침표, 퍼센트 기호)를 제외한 특수기호 사용 불가 </Text>
          )
        }
      </div>
      {
        errorMsg && (
          <Text type="danger">{errorMsg}</Text>
        )
      }
    </article>
  )
}
