import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Typography } from 'antd'
import { Goods, BusinessDayItem } from '@center/api'
import { numberFormat } from '@wadiz/utils'
import { SelectDate } from '../../AdRegistrationModal'

interface AdSelectDateProps {
  selectedGoods: Goods
  selectedDate: DateFormat
  businessDays: BusinessDayItem[]
  price: number
  holidayPrice: number
  impMinPeriod: number
  handleDate: (date: DateFormat, normalDays: number, holidays: number) => void
  disabled?: boolean
  errorMsg?: string
  isSuperUser: boolean
}
interface DateFormat {
  startDate: string // dateFormat
  endDate: string // dateFormat
}

const { Text } = Typography
const dateFormat = 'YYYY-MM-DD'
export const AdSelectDate: React.FC<AdSelectDateProps> = ({
  selectedGoods,
  selectedDate,
  businessDays,
  handleDate,
  price,
  holidayPrice,
  impMinPeriod,
  disabled = false,
  errorMsg,
  isSuperUser,
}) => {
  const [checkHolidays, setCheckHolidays] = useState({
    normalDays: 0,
    holidays: 0,
  })

  const countHolidays = (startDate: string) => {
    const holidayList = businessDays.filter((item) => item.type !== 'o')
    let normalDays = 0,
      holidays = 0
    // 휴일, 평일 체크
    const dateRange = new Array(impMinPeriod)
      .fill('')
      .map((_, idx) => dayjs(startDate).add(idx, 'day').format(dateFormat))

    dateRange.forEach((date) => {
      if (holidayList.some((i) => date === i.eventDate)) {
        holidays += 1
      } else {
        normalDays += 1
      }
    })
    return {
      normalDays,
      holidays,
    }
  }

  const onSelectDate = (date: DateFormat) => {
    const { normalDays, holidays } = countHolidays(date.startDate)
    handleDate(date, normalDays, holidays)
  }

  useEffect(() => {
    if (!businessDays) return
    const { normalDays, holidays } = countHolidays(selectedDate.startDate || dayjs().format(dateFormat))

    setCheckHolidays({
      normalDays,
      holidays,
    })
  }, [businessDays, selectedDate])

  return (
    <article>
      <label>광고기간</label>
      <SelectDate
        selectedGoods={selectedGoods}
        businessDays={businessDays}
        selecedDate={selectedDate}
        onSelect={onSelectDate}
        disabled={disabled}
        isSuperUser={isSuperUser}
      />
      <p style={{ marginTop: 12, height: 22 }}>
        평일: {checkHolidays.normalDays}일 - {numberFormat(price * checkHolidays.normalDays) || 0}원 /
        휴일: {checkHolidays.holidays}일 - {numberFormat(holidayPrice * checkHolidays.holidays) || 0}원
      </p>
      {errorMsg && <Text type="danger">{errorMsg}</Text>}
    </article>
  )
}
