import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const LoadingBar: React.FC = () => (
  <Spin
    indicator={
      <LoadingOutlined style={{ fontSize: 50, color: "#00b2b2" }} spin />
    }
  />
);
