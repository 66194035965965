import React from 'react';
import { BgColorResult } from '@center/api'
import cx from 'classnames'
import styles from './BgColorList.module.scss';

interface BgColorListProps {
  defaultColor?: string;
  bgColorDataList: BgColorResult[];
  onClick: (bgColorData: BgColorResult) => void;
}

export const BgColorList: React.FC<BgColorListProps> = ({
  defaultColor,
  bgColorDataList = [],
  onClick,
}) => {

  return (
    <div className={styles.container}>
      {bgColorDataList.map(bgColorData => (
        <button
          key={bgColorData.bgColor}
          className={cx(styles.colorButton, { [styles.active]: bgColorData.bgColor === defaultColor })}
          style={{ backgroundColor: bgColorData.bgColor }}
          onClick={() => onClick(bgColorData)}>{bgColorData.bgColor}</button>
      ))}
    </div>
  )
}
